@mixin shopping-carts-overview($theme) {
  $layout: map-get($theme, layout);

  $color-gray: mat-color($mp-gray, 900);

  .mpms-shopping-carts-overview {
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-title {
      color: $color-gray
    }

    .shopping-cart-panel {
      display: block;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}
