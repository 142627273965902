@import 'colors';

@import '../content-section.scss';

@mixin uebersicht-content-section {
  .mpcm-uebersicht-content-section {
    background-color: white;
    display: block;

    @include content-section;
  }

  .red {
    color: $color-state-red-500 !important;
  }

  .green {
    color: $color-state-green-500 !important;
  }
}
