@import 'colors';

@mixin global-article-toggle-global {
  .mpcm-global-article-toggle {
    .mat-slide-toggle.mat-checked {
      .mat-slide-toggle-thumb,
      .mat-slide-toggle-ripple .mat-ripple-element {
        background-color: $color-gold-450;
      }

      .mat-slide-toggle-bar {
        background-color: $color-gray-400;
      }
    }
  }
}
