@import 'variables';
@import '../../style/highlight-animation';

@mixin purchase-requisition-panel($theme) {
  $font-mono: 'Courier New', Courier, monospace;
  $color-gray: mat-color($mp-gray, 600);

  $color-highlight: var(--gold-100);

  .mpdm-purchase-requisition-panel {
    mat-expansion-panel.purchase-requisition-panel {
      line-height: 21px;
      height: auto;
      margin-bottom: 1.5 * $default-thickness;
      border-radius: 4px;

      > mat-expansion-panel-header {
        height: auto;
        padding: 0;

        &.highlight {
          @include highlight-animation($color-highlight);
        }

        span.mat-content {
          flex-direction: column;
          height: 100%;
          margin: 0;
        }
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    .requisition-identification {
      margin-bottom: 0.5 * $default-thickness;

      &__requisition-name {
        mat-icon,
        &-text {
          display: inline-block;
          vertical-align: middle;
          line-height: 20px;
          font-size: 20px;
          color: $color-gray;
        }

        mat-icon {
          margin: 0 0.5 * $default-thickness 0 0;
          height: 18px;
          width: 18px;
        }

        &-text {
          margin: 0;
          line-height: 24px;
        }
      }

      &__cost-center {
        color: $color-gray;
        font-size: 14px;
        line-height: 18px;
        font-weight: normal;
        margin-top: $default-thickness;

        &-name {
          font-weight: bold;
        }

        &-number {
          line-height: 18px;
          display: inline-block;
          margin-left: 4px;
        }
      }
    }

    .purchase-requisition-panel-header__creator-name,
    .purchase-requisition-panel-header__creator-type {
      color: $color-gray;
      font-size: 13px;
      line-height: 16px;
      display: block;
    }

    .purchase-requisition-panel-header__creator-type {
      font-weight: normal;
      margin-top: 4px;
    }

    .purchase-requisition-panel-header__total-price-label {
      color: $color-gray;
      display: block;
      font-size: 13px;
      line-height: 16px;
      font-weight: normal;
    }

    .purchase-requisition-panel-header__toggle-requisition-position-icon {
      color: $color-gray;
    }

    .font-mono {
      font-family: $font-mono;
      font-weight: bold;
    }
  }
}

:host {
  display: block;

  .purchase-requisition-panel-header {
    padding: $default-thickness * 1.5 $default-thickness * 2 $default-thickness;
    display: flex;
    align-items: center;

    &__requisition-details-column {
      flex: 1 1 auto;
      min-width: 0;
    }

    &__requisition-assignment-column {
      flex: 0 1 15%;
      min-width: 0;
      text-align: center;
    }

    &__requisition-positions-column {
      flex: 0 1 15%;
      min-width: 0;
      text-align: center;
    }

    &__requisition-total-price-column {
      flex: 0 1 230px;
      min-width: 0;
      text-align: center;
    }

    &__toggle-requisition-positions-column {
      flex: 0 1 50px;
      min-width: 0;
      text-align: center;
    }
  }
}
