@import 'colors';

@import '../platformcatalog-panel-common';

@mixin plattformkatalog-create($theme) {
  $primary: map-get($theme, primary);

  $yellow: mat-color($primary, 50);
  $yellow-light: $color-primary-10;

  .mp-plattformkatalog-create {
    display: block;
    margin: 0 0 $default-thickness * 4 0;

    @include platformcatalog-panel-common;

    .mat-expansion-panel {
      .mat-expansion-panel-header.mat-expanded {
        &,
        &:hover {
          background-color: $yellow;
        }
      }

      .tab-row {
        background-color: $yellow-light;
      }
    }
  }
}
