@import 'colors';

@mixin articles-count-tile($theme) {
  $layout: map-get($theme, layout);
  $accent: map-get($theme, accent);

  $color-accent: mat-color($accent, 600);

  $color-layout: mat-color($layout, 500);

  $color-hover: $color-gold-50;

  .mpms-articles-count-tile {
    .articles-count-card {
      &__label,
      &__value {
        font-weight: bold;
      }

      &__label {
        color: $color-layout;
        font-size: 14px;
        white-space: nowrap;
      }

      &__value {
        font-size: 20px;
      }

      &:hover:not(.articles-count-card--disabled) {
        cursor: pointer;
        background-color: $color-hover;

        .mpms-show-all-link {
          .show-all-link {
            &__label {
              color: $color-accent;
            }
          }
        }
      }
    }
  }
}

:host {
  .articles-count-card {
    display: flex;
    align-items: flex-end;

    &__container {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-width: 0;
    }

    &__content {
      flex-shrink: 0;
    }
  }
}
