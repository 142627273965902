@mixin error-page-layout($theme) {
  $accent: map-get($theme, accent);

  $color-accent: mat-color($accent, 500);

  .mpe-error-page-layout {
    .error-page-header {
      background-color: white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);

      &__logout-button {
        color: $color-accent;
      }
    }

    .error-page-content {
      background-color: white;
    }
  }
}

:host {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;

  .error-page-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    height: 70px;
    z-index: 10;
    position: relative;

    &__logo {
      height: 55px;
    }

    &__logout-button {
      position: absolute;
      right: 20px;
      top: 16px;
    }
  }

  .error-page-content {
    flex-grow: 1;
    text-align: center;
    padding: 50px;
    overflow: auto;
  }
}
