@mixin klinik-tab-aufgaben($theme) {
  $layout: map-get($mp-theme, layout);
  $light-blue: mat-color($layout, 50);
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 300);
  $color-orange: mat-color($mp-state, orange);
  $box-shadow: 0 0 1px 1px rgb(0 0 0 / 5%), 0 1px 1px 0px rgb(0 0 0 / 10%);

  .mp-tab-aufgaben-clinic {

    .headline {
      margin-top: 2*$default-thickness;

      .mat-h3 {
        display: flex;
        align-items: center;
        color: $color-gray;

        mat-icon {
          padding-right: $default-thickness;
        }
      }
    }

    mat-card {
      margin-bottom: $default-thickness * 2;
      padding: 0 !important;
      border: 1px solid $light-blue;
      box-shadow: $box-shadow;

      .table-container {
        flex: 1;
        background-color: #fafafa;

        .flex-row {
          display: flex;
          align-items: center;
          height: 100%;
          justify-content: space-between;

          .saved-state {
            align-items: center;
            display: flex;
            color: $color-gray;

            &--icon {
              padding-right: 8px;
            }
          }
        }

        table {
          table-layout: fixed;
        }

        tr {

          td,
          th {
            padding-right: 8px;
            padding-left: 16px;
          }

          th {
            color: $color-gray;

            .flex-row {
              justify-content: flex-start;
            }
          }

          .task-info-entity {
            font-size: 16px;
          }

          .task-info-verb {
            font-weight: normal;
          }

          th mp-task-info {
            margin: 0;

            .mp-task-info {
              margin: 0 4px 0 0;

              > span {
                font-size: 18px;
                line-height: 18px;
              }
            }

            mat-icon {
              font-size: 24px!important;
              line-height: 24px!important;
              width: 24px;
              height: 24px;
              margin-right: 6px;
            }
          }

          .mat-column-einheit {
            font-weight: bold;

            &.color-orange {
              color: $color-orange;
            }

            &.color-gray {
              color: $color-gray;
            }
          }

          td.mat-column-artikel {
            color: $color-gray;
          }

          .mat-column-actions {
            width: 70px;
            text-align: right;

            .action-button {
              margin-left: $default-thickness;
              color: $color-gray;
            }
          }

          .mat-column-bezugsquelle {

            mpsm-catalog-supplier-selector {
              margin: 0;
              flex: 1 1 auto;
            }

            .action-button {
              margin-left: $default-thickness;
              color: $color-gray;
            }
          }

          &.row--removed td {
            opacity: 0.5;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
