@import 'colors';

$font-mono: 'Courier New', Courier, monospace;

@mixin article-card-theme($theme) {
  $layout: map-get($theme, layout);
  $primary: map-get($theme, primary);

  $color-card-bg-hover: $color-gold-25;

  .mpms-article-card {
    margin: 0 0 $default-thickness 0;
    display: block;

    mat-card.mat-card {
      transition: background-color 150ms ease-in-out;
    }

    &:hover {
      mat-card.mat-card {
        background-color: $color-card-bg-hover;
      }
    }

    .article-item {
      &__body {
        position: relative;
      }

      &__head {
        display: flex;
        justify-content: space-between;
        padding-left: 160px;
        box-sizing: border-box;
        cursor: pointer;
      }

      &__preview {
        width: 135px;
        height: 100%;

        position: absolute;

        .article-image {
          --image-placeholder-background-color: transparent;
        }

        &-frame {
          height: 100%;
        }
      }

      &__details {
        width: 0;
        flex-grow: 1;
      }

      &__favorite {
        flex-shrink: 0;
      }

      &__foot {
        position: relative;
        padding-left: 160px;
        box-sizing: border-box;
        display: flex;
      }

      &__units {
        flex: 1 1 auto;
        min-width: 0;
        line-height: 16px;

        &,
        &--price {
          margin-top: 6px;
        }
      }
    }

    .placeholder-text {
      font-size: 13px;
      color: gray;
    }
  }
}

:host {
  .font-mono {
    font-size: 14px;
    font-weight: 700;
    font-family: $font-mono;
  }

  .bold {
    font-weight: bold;
  }
}
