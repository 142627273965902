@mixin copy-to-clipboard-button-global{
  .mp-copy-to-clipboard-button {
    .copy-to-clipboard-button {
      &.mat-icon-button.cdk-program-focused {
        .mat-button-focus-overlay {
          opacity: 0;
        }
      }
    }
  }
}
