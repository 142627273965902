@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin select-overrides {
  .mat-select-placeholder {
    color: color-utils.add-opacity-to-color-variable($color-foreground-secondary-text, 0.42);
  }

  .mat-select-panel {
    .mat-option.mat-selected:not(.mat-option-multiple) {
      background: color-utils.add-opacity-to-color-variable($color-background-hover, 0.12);
    }
  }
}
