@mixin entity-item-slots($theme) {
  $accent: map-get($theme, accent);
  $layout: map-get($theme, layout);
  $accent-color: mat-color($accent, 500);
  $color-layout: mat-color($layout, 500);

  $item-border-radius: 4px;
  $item-remove-background: #a1a1a1;

  $color-text-gray: mat-color($mp-gray, 600);
  $color-icon: mat-color($mp-gray, 600);

  $item-border-radius: 4px;
  $item-background--removed: #a1a1a1;

  $text-gray-dark: rgb(36, 36, 36);
  $text-gray-light: #767676;

  $font-label: 700 #{16px}/#{48px} Roboto;
  $font-meta: 300 #{14px}/#{48px} Roboto;

  $color-orange: mat-color($mp-state, orange);
  $color-red: mat-color($mp-state, red);

  $vgap: 36px;

  .mp-entity-item-slots {
    display: block;
    margin: 0 0 10px 0;

    .entity-item {
      box-sizing: border-box;

      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: baseline;

      padding: 0 0 0 0;
      margin: 0;

      background: #fff;
      border-radius: $item-border-radius;

      border: 1px rgb(0 0 0 / 5%) solid;
      border-bottom: 1px rgb(0 0 0 / 15%) solid;

      position: relative;
      overflow: hidden;

      &__slot-drag-handle,
      &__slot-icon {
        flex: 0 0 auto;
        align-self: center;

        color: $color-icon;

        .mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
          vertical-align: middle;
        }
        &:empty {
          display: none;
        }
      }

      &__slot-status {
        flex: 0;
        min-width: 0;
      }

      &__slot-content {
        display: flex;
        flex: 1 1 auto;
        align-items: baseline;

        min-width: 0;
        padding: 0 0 0 $default-thickness * 1.5;
        margin: 0;

        font: $font-label;
        color: $text-gray-light;

        mp-catalog-type-icon {
          mat-icon {
            vertical-align: middle;
            font-size: 20px;
            margin: 0 8px 0 0;
          }
        }

        .name {
          flex: 0 1 auto;

          margin: 0 $default-thickness * 2 0 0;
          min-width: 60px;
        }

        .meta {
          font: $font-meta;
          color: $color-text-gray;
          flex: 0 1 auto;
          margin: 0 $default-thickness * 2 0 0;
        }
      }

      &__slot-actions {
        color: $color-text-gray;
        background-color: #fff;
        flex: 0 0 auto;
        display: flex;
        min-width: 60px;
        margin: 1px;
        padding-right: $default-thickness * 0.5;
        z-index: 9;

        .mat-icon-button {
          line-height: 48px;
          height: 48px;
          width: 48px;

          .mat-icon {
            font-size: 16px;
            height: 16px;
            line-height: 16px;
            width: 16px;
            vertical-align: middle;
            color: $color-text-gray;

            &:not(.mat-button-disabled) {
              border-color: currentColor;
            }
          }

          &:hover .mat-icon {
            color: $text-gray-dark;
          }
        }
      }
    }
  }

  mp-entity-item-slots {
    .entity-item-head {
      display: flex;
      width: 100%;
    }

    .entity-item__slot-footer {
      width: 100%;
    }

    mp-entity-item-footer {
      display: flex;
      width: 100%;
      margin-top: -4px;
    }
  }

  /**
  * Drag & Drop
  **/

  .cdk-drop-list-dragging mp-sortable-katalog:not(.cdk-drag-placeholder) {
    display: block;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

  .cdk-drag-preview {
    box-sizing: border-box;
    opacity: 1;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    transform: scale(2);
  }

  .drag-placeholder {
    display: flex;
    box-sizing: border-box;

    padding: 13px 10px;
    margin: 0 0 8px 0;

    border: dashed 2px rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    line-height: 0;
    background: rgba(0, 0, 0, 0.05);

    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

    span {
      flex: 1 1 100px;

      &:before {
        content: ' ';
        display: inline-block;

        width: 100%;
        height: 18px;

        background: rgba(0, 0, 0, 0.05);
      }
    }

    span:nth-child(1) {
      flex: 0 1 55px;

      padding-right: 24px;
      padding-left: 46px;
    }

    span:nth-child(2) {
      flex: 0 1 150px;
    }

    span:nth-child(3) {
      flex: 1 0 auto;

      padding-left: 32px;
      text-align: right;

      &:before {
        width: 80px;
      }
    }
  }

  .list-placeholder {
    box-sizing: border-box;

    width: 100%;
  }

  .mat-paginator {
    background-color: transparent;
  }
}
