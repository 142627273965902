@import 'variables';
@import 'colors';

@mixin hitlists-quick-start($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 100);

  $no-hitlists-color: #757575;

  $color-hover: $color-gold-50;

  .mpms-hitlists-quick-start {
    .hitlists-quick-start {
      &__divider {
        border-color: $color-light-gray;
      }

      &__item {
        &:hover {
          cursor: pointer;
          background-color: $color-hover;
        }
      }
    }

    .header-title {
      color: $color-gray;

      &__text {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .no-hitlists {
      color: $no-hitlists-color;
      font-size: 14px;
      padding: $default-thickness;
    }
  }
}

:host {
  display: block;

  .hitlists-quick-start {
    padding: 0;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__header,
    &__item {
      padding: $default-thickness;
      margin: 0;
    }
  }

  .header-title {
    display: flex;
    align-items: center;
    position: relative;
    right: 4px;

    &__text {
      margin-left: 0.5 * $default-thickness;
    }

    &__icon {
      height: 22px;
      width: 22px;
    }
  }

  .hitlists-loading-spinner {
    padding-bottom: $default-thickness;
  }
}
