@import 'variables';

$font-mono: 'Courier New', Courier, monospace;

@mixin article-item-details-theme($theme) {
  $layout: map-get($theme, layout);
  $layout-color: mat-color($layout, 500);
  $color-gray: map-get($mp-gray, 500);

  .mpdm-article-item-details {
    .article-item-details__supplier-info {
      color: $color-gray;
    }

    .supplier-info__clinic-icon {
      font-size: 16px;
    }
  }
}

:host {
  display: block;

  .font-mono {
    font-size: 14px;
    font-weight: 700;
    font-family: $font-mono;
  }

  .article-item-details__supplier-info {
    display: flex;
  }

  .supplier-info__clinic-article-info-container {
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
  }

  .supplier-info__supplier-name-container,
  .supplier-info__ref-data-container,
  .supplier-info__clinic-article-info-container {
    &:not(:last-child) {
      padding-right: $default-thickness;
    }
  }

  .supplier-info__clinic-article-label {
    display: inline-block;
    vertical-align: middle;
  }

  .supplier-info__clinic-icon {
    font-size: 18px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-top: -1px;
  }
}
