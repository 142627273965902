@import 'variables';
@import 'colors';

@mixin shopping-cart-nav-item-global {
  .mpms-shopping-cart-nav-item {
    .item-icon {
      .mat-badge-content {
        background-color: $color-app-header-text;
        color: $color-background-app-bar;
        border: 2px solid var(--shopping-cart-nav-item-badge-border-color, $color-background-app-bar);
        transition: var(--shopping-cart-nav-item-badge-border-color-transition, none);
        z-index: 0;
        width: 14px;
        height: 14px;
        line-height: 14px;
      }

      &.mat-badge-small.mat-badge-overlap {
        &.mat-badge-after {
          .mat-badge-content {
            top: -7px;
            right: -5px;
          }
        }
      }
    }
  }
}
