@mixin numeric-spinner($theme) {
  .mp-numeric-spinner {
    display: flex;
    align-items: center;

    input[type='number'] {
      appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    mat-form-field {
      max-width: 64px;
      text-align: center;
    }
  }
}
