@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin _toggle-checked-bar-color($color) {
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: color-utils.add-opacity-to-color-variable($color, 0.54);
    }
  }
}

@mixin slide-toggle-overrides {
  .mat-slide-toggle {
    @include _toggle-checked-bar-color($color-accent-500);

    &.mat-primary {
      @include _toggle-checked-bar-color($color-primary-500);
    }

    &.mat-warn {
      @include _toggle-checked-bar-color($color-warn-500);
    }
  }
}
