@import 'mixins';
@import 'variables';
@import 'colors';

@mixin news-organisations-selector($theme) {
  $color-background: rgba(0, 0, 0, 0.04);

  $color-border: mat-color($mp-gray, 100);

  $color-light-gray: mat-color($mp-gray, 300);
  $color-gray: mat-color($mp-gray, 500);

  $table-row-accent: $color-layout-10;

  .mp-news-organisations-selector {
    background-color: $color-background;

    .organisations-selector-header {
      color: $color-gray;
      font-weight: bold;
    }

    .organisations-selector-list {
      border: 4px solid $color-border;
      border-radius: 8px;

      &__item {
        background-color: white;

        &:not(:last-child) {
          border-bottom: 1px solid $color-border;
        }

        &:nth-child(even) {
          background-color: $table-row-accent;
        }
      }
    }

    .organisation-item {
      &__organisation-name {
        font-size: 16px;
      }

      &__organisation-name,
      &__delete-icon {
        color: $color-gray;
      }
    }

    .organisations-selector-actions {
      &__add-action-button {
        color: $color-gray;
      }

      &__action-info {
        color: $color-light-gray;
        font-weight: bold;
      }
    }
  }
}

:host {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  // NOTE: Padding is set to that value to match the form-field default padding
  padding: 0.75em;
  gap: 0.5 * $default-thickness;

  .organisations-selector-header {
    display: flex;
    align-items: center;
    gap: 0.5 * $default-thickness;
    flex-wrap: nowrap;

    &__icon {
      min-width: 24px;
    }

    &__text {
      flex-grow: 1;
      width: 0;
    }
  }

  .organisations-selector-list {
    max-height: 150px;
    overflow-y: auto;
    padding-top: 0;

    &__item {
      height: auto;
    }
  }

  .organisation-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 30px;

    &__delete-icon {
      @include icon-button-size(30px, 18px);
    }
  }

  .organisations-selector-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5 * $default-thickness;

    &__action-info {
      width: 0;
      flex-grow: 1;
      text-align: right;
    }
  }
}
