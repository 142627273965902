@mixin facet-date-selector-global {
  $material-calendar-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  .mp-facet-date-selector {
    &__calendar {
      background-color: white;
      width: 300px;
      border-radius: 4px;
      box-shadow: $material-calendar-box-shadow;
    }
  }
}
