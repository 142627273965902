@import 'variables';
@import 'colors';

@mixin inline-edit($theme) {
  $accent: map-get($theme, accent);

  $button-text-color: map-get($mp-gray, 700);

  $accent-color-light: mat-color($accent, 50);

  $color-focus: $color-gold-500;
  $color-focus-bg: $color-gold-50;

  .mp-inline-edit {
    .mat-form-field {
      &.mat-focused .mat-form-field-underline,
      &.mat-focused .mat-form-field-ripple,
      .mat-form-field-underline {
        background-color: $color-focus;
      }

      &.mat-focused .mat-form-field-label,
      .mat-form-field-label {
        color: $color-focus;
      }

      .mat-form-field-infix {
        width: auto;
      }
    }

    .inline-form {
      background-color: $color-focus-bg;

      &__confirm-btn,
      &__reject-btn {
        color: $button-text-color;
        border: none;
        background: none;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

:host {
  display: block;

  .inline-form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: $default-thickness;

    &__confirm-btn {
      margin-left: 5px;
    }
  }
}
