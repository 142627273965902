@mixin purchase-requisition-current-state($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $color-success-green: mat-color($mp-state, green);
  $color-failed-red: mat-color($mp-state, red);

  .mpdm-purchase-requisition-current-state {
    margin: 0;

    .requisition-state__current-state {
      gap: $default-thickness;

      .requisition-state__current-state-accept-state-button {
        width: 220px;
        background-color: white;
        border: 1px solid $color-success-green;
        color: $color-success-green;
        box-shadow: none;
      }

      &-customer-info,
      &-customer-number {
        color: $color-gray;
      }

      &-customer-number {
        margin: 0 0.5 * $default-thickness;
      }

      &-failed {
        color: $color-failed-red;
      }

      &-row {
        display: flex;
        align-items: center;
      }

      &-details {
        margin-left: 0.5 * $default-thickness;
        &-time {
          color: $color-gray;
          font-weight: normal;
        }

        &-info {
          margin-left: 0.5 * $default-thickness;
          color: $color-gray;

          // TODO: Style this icon
          + .mat-icon {
            vertical-align: middle;
            margin-left: 4px;
          }
        }

        &-label {
          margin-right: 0.5 * $default-thickness;
        }
      }

      .btn.btn--processing {
        text-align: center;
        min-width: 220px;
        background-color: rgba(255, 255, 255, 0.75);

        mat-spinner {
          display: inline-block;
        }
      }
    }
  }
}

:host {
  .requisition-state__current-state {
    display: flex;
    align-items: center;

    &-customer-info {
      font-size: 13px;
      line-height: 16px;
      font-weight: normal;
    }

    &-customer-number {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
    }

    &-failed {
      font-size: 13px;
    }

    &-details {
      &-time,
      &-label,
      &-info {
        font-size: 12px;
        line-height: 16px;
      }

      &-label {
        padding: 2px;
      }
    }
  }
}
