@import 'mixins';
@import 'variables';

@mixin artikel-autocomplete-searchfield-global {
  .mpcm-artikel-autocomplete-searchfield {
    &__autocomplete-panel {
      .article-item {
        display: flex;
        padding-top: $default-thickness;

        &__icon {
          flex: 0 0 48px;
          height: 48px;
          margin-right: 0.5 * $default-thickness;
          margin-left: -0.5 * $default-thickness;
        }

        &__type-icon {
          .mat-icon {
            margin-right: 0;
          }
        }
      }

      .article-item-text {
        flex: 1;
        min-width: 0;
        padding-bottom: $default-thickness;

        font-size: 14px;

        &__header {
          font-size: 16px;
          line-height: 20px;
          margin: 0;
        }

        &__supplier {
          display: flex;
          align-items: center;
          gap: 0.5 * $default-thickness;

          &-name {
            flex: 0 1 auto;
            min-width: 0;
          }

          &-icon.mat-icon {
            margin-right: 4px;
            @include icon-size(16px);
          }

          &-ref-number {
            flex: 1 0 auto;
            min-width: 0;
          }
        }
      }
    }
  }
}
