@import 'variables';

@mixin page-favorites($theme) {
  $layout: map-get($mp-theme, layout);
  $color-layout: mat-color($layout, 500);

  $color-gray-light: #a6a6a6;

  .mp-page-favorites {
    .aside__head-strapline {
      font-weight: bold;
      color: $color-gray-light;
      font-size: 14px;
      line-height: 14px;
    }

    .aside__head-l mat-icon {
      width: 42px;
      height: 42px;
      font-size: 42px;
      line-height: 50px;
      margin-right: 8px;
      color: $color-layout;
    }

    .no-list-selected-info {
      color: $color-layout;
    }
  }
}

:host {
  display: block;
  $aside-padding: 0 1.5 * $default-thickness 0 $default-thickness;

  .aside__head {
    display: flex;
    padding: $aside-padding;

    .aside__head-title {
      margin: 0 0 $default-thickness;
      padding: 0;
    }

    .aside__head-strapline {
      display: inline-block;
    }
  }

  .aside__head-action {
    padding: $aside-padding;
    margin: $default-thickness 0 2 * $default-thickness;
  }

  .aside__list {
    padding: $aside-padding;
  }
}
