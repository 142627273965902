@use 'variables';

/// BREAKPOINT MIXIN
///
/// Usage:
/// @include breakpoint(xl) {
///   background: purple;
/// }

@mixin breakpoint($name) {
  @if not map-has-key(variables.$breakpoints, $name) {
    @warn "Invalid breakpoint `#{$name}`.";
  } @else {
    @media (min-width: map-get(variables.$breakpoints, $name)) {
      @content;
    }
  }
}

/// Icon size mixin
/// Sets all of the necessary css properties to change icon size
@mixin icon-size($icon-size) {
  font-size: $icon-size;
  line-height: $icon-size;
  width: $icon-size;
  height: $icon-size;
}

/// Button icon size mixin
/// Sets all of the neccesary css properties to change icon button size with its included icon
@mixin icon-button-size($button-size, $icon-size) {
  width: $button-size;
  height: $button-size;
  line-height: $button-size;

  .mat-icon {
    @include icon-size($icon-size);
  }
}

@mixin mp-page-aside-padding {
  padding: $default-thickness * 1.5 $default-thickness * 1.5 $default-thickness $default-thickness * 1.5;

  @include breakpoint(lg) {
    padding: $default-thickness * 2 $default-thickness * 1.5 $default-thickness * 2 $default-thickness * 2;
  }
}
