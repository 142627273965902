@mixin error-alert($theme) {
  $layout: map-get($theme, layout);

  $warn: map-get($mp-theme, warn);
  $dark-red: mat-color($warn, 500);
  $medium-red: mat-color($warn, 200);
  $light-red: mat-color($warn, 50);

  .error-alert {
    display: flex;
    padding: $default-thickness;
    margin-bottom: $default-thickness;

    border: 1px solid $medium-red;
    border-radius: 4px;

    background-color: $light-red;

    .error-alert-icon {
      color: $dark-red;
      margin-right: $default-thickness;
    }

    .error-alert-bold-text {
      @include mat-typography-level-to-styles($mp-typography, body-2);
    }

    .error-alert-message {
      margin-left: $default-thickness * 0.5;
    }
  }
}
