@import 'variables';

@mixin warning-state-filter($theme) {
  $layout: map-get($theme, layout);

  $text-color: mat-color($layout, 500);

  .mpcm-warning-state-filter {
    .warning-state-filter {
      cursor: pointer;
      overflow: hidden;
      color: $text-color;

      &__icon {
        color: inherit;
        font-size: 16px;
        line-height: normal;
      }

      &__title {
        font-size: 12px;
        font-weight: bold;
      }

      &__toggle {
        font-size: 14px;
        color: #757575;
      }
    }
  }
}

:host {
  .warning-state-filter {
    display: flex;
    align-items: center;
    padding: 0.5 * $default-thickness $default-thickness;

    &__icon {
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
    }

    &__title {
      flex: 1;
      box-sizing: border-box;
      height: 30px;
      line-height: 30px;
      padding-left: 12px;
    }

    &__toggle {
      flex: 0;
      padding: 3px 0.5 * $default-thickness;
    }
  }
}
