@import 'swiper/scss';
@import 'swiper/scss/scrollbar';
@import 'variables';
@import 'colors';

@mixin carousel($theme) {
  $color-background: mat-color($mp-gray, 50);
  $color-light-gray: mat-color($mp-gray, 200);
  $color-gray: mat-color($mp-gray, 400);

  :root {
    --swiper-scrollbar-bottom: 0;
    --swiper-scrollbar-sides-offset: 0;
    --swiper-scrollbar-bg-color: #{$color-background};
    --swiper-scrollbar-drag-bg-color: #{$color-light-gray};
    --swiper-scrollbar-size: 8px;
  }

  .mpms-carousel {
    .carousel-container {
      &__navigation-prev,
      &__navigation-next {
        color: $color-gray;
        cursor: pointer;
        background-color: $color-background;
      }

      &__navigation--disabled {
        cursor: default;
        color: $color-gray-150;
      }
    }

    .carousel {
      background-color: transparent;

      &__slide {
        text-align: center;
        font-size: 18px;
        background: white;
        border-right: 2px solid $color-background;

        &:first-child {
          border-left: 2px solid $color-background;
        }
      }
    }
  }

  .mpms-carousel {
    display: flex;

    .carousel-container {
      display: flex;
      width: 100%;
      align-items: center;

      &__navigation-prev,
      &__navigation-next {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 4px;
      }
    }

    .carousel {
      width: 0;
      flex: 1 1 0;

      &__slide {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 150px;
        height: auto;
        box-sizing: border-box;
      }

      &__scrollbar {
        width: 100% !important;
        border-radius: 0;
      }
    }
  }
}
