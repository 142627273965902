:host {
  display: block;
  color: #757575;
}


@mixin packungseinheiten-dialog($theme) {
  $primary: map-get($theme, primary);
  $layout: map-get($theme, layout);
  $focus-color: mat-color($primary, 500);
  $layout-color: mat-color($layout, 400);

  $grey: #757575;
  $color-dark-gray: mat-color($mp-gray, 600);
  $color-dark-light: mat-color($mp-gray, 300);


  mp-packungseinheiten-dialog button.mat-stroked-button.packing-button {
    border: 0;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    background: #fff;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.04);
      box-shadow: none;
      color: $color-dark-light;
    }
  }

  mp-packungseinheiten-dialog-content {
    .head-row {
      display: flex;
      justify-content: space-between;

      .mat-dialog-title {
        margin: 0 0 4px;
        color: $layout-color;
      }

      .dialog-action-close {
        color: $color-dark-gray;
      }
    }

    .dialog-text {
      color: $grey;
    }

    .table-container tr td,
    .table-container tr th {
      padding-right: 8px;
      padding-left: 16px;
      height: 55px;
    }

    table {
      table-layout: fixed;
      border-top: 1px #e3eced solid;

      td.mat-column-bezugsquelle {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .row--no-data {
        height: 120px;
        background-color: #f5f5f5;
      }

      .no-data {
        padding: 16px;
        font-size: 16x;
        display: block;
        text-align: center;
        color: #757575;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }
    }

    .mat-dialog-content {
      margin-top: 24px;
      padding: 0;
      height: 700px;
    }

    .action-button {
      color: $grey;
      &:hover {
        color: black;
      }
    }

    .table-container__paginator {
      background-color: white;
    }
  }

  .packungseinheiten-dialog-content-panel {
    .mat-dialog-container .mat-card {
      padding: 0;
      height: 100%;
    }

    mat-dialog-container {
      padding-bottom: 0!important;
      padding-top: 32px!important;
      background: #fff;
    }
  }
}
