@import 'colors';

@import '../content-section.scss';

@mixin merkmale-content-section {
  .mpcm-merkmale-content-section {
    background-color: white;
    display: block;

    $color-gray: $color-gray-600;
    $color-light-gray: $color-gray-300;

    @include content-section;

    //TODO: Responsive design for smaller displays

    .artikel-section-col-left {
      flex-wrap: wrap;
      flex-direction: column;

      .artikel-section-value {
        .artikel-section-label {
          font-weight: bold;
          text-align: left;

          mat-icon {
            top: 12px;
            font-size: 15px;
            position: relative;
          }

          .category-type {
            margin-left: 5px;
            color: $color-gray;
          }
          .category-version {
            margin-left: 15px;
            color: $color-gray;
            border: 1px solid $color-light-gray;
            border-radius: 4px;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }

    .artikel-section-col-right {
      flex-wrap: wrap;
      flex-direction: column;

      .artikel-section-value {
        .artikel-section-label {
          font-weight: bold;

          mat-icon {
            top: 12px;
            font-size: 15px;
            position: relative;
          }

          .category-type {
            margin-left: 5px;
            color: $color-gray;
          }
          .category-version {
            margin-left: 15px;
            color: $color-gray;
            border: 1px solid $color-light-gray;
            border-radius: 4px;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
    }

    mpcm-breadcrumb-item {
      display: flex;
      flex-direction: column;
    }
  }
}
