@import 'colors';

@mixin zendesk-support-form-dialog-global {
  .mp-zendesk-support-form-dialog {
    &-container {
      .mat-dialog-container {
        background-color: $color-background-background;

        max-width: 80vw;
        width: 600px;
        border-radius: 12px;
        padding: 64px;
        position: relative;
      }
    }

    &__attachments-info-tooltip {
      max-width: 300px;
      white-space: pre-line;
    }
  }
}
