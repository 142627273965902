@import 'colors';

@mixin platformcatalog-panel-common {
  $btn-border-color: $color-gray-100;
  $btn-icon-color: $color-gray-600;

  .actions-slide-toggle {
    &.mat-disabled {
      opacity: unset;
      .mat-slide-toggle-label,
      .mat-slide-toggle-thumb-container {
        cursor: unset;
      }
    }
    .mat-slide-toggle-ripple {
      display: none;
    }
  }

  .mat-button.mat-button-disabled {
    .actions-slide-toggle {
      &.mat-disabled {
        .mat-slide-toggle-bar {
          opacity: 0.5;
        }
      }
    }
  }

  .actions-button {
    line-height: 28px;
    padding: 0 8px;
    min-width: 0;
    margin-left: 8px;
    flex: none;

    &.mat-button-disabled:not(.mat-stroked-button) {
      color: #fff;
    }

    &.mat-stroked-button {
      border-color: $btn-border-color;
      &:not(.mat-button-disabled) {
        color: $btn-icon-color;
      }
    }

    mat-icon {
      font-size: 16px;
      height: 20px;
      width: 20px;
    }
  }
}
