@mixin page-hitlists-aside($theme) {
  $layout: map-get($mp-theme, layout);

  $color-layout: mat-color($layout, 500);
  $color-gray-light: #a6a6a6;

  $aside-padding: 0 24px 0 16px;

  .mpms-page-hitlists-aside {
    .aside__head {
      padding: $aside-padding;

      &-icon mat-icon {
        margin-right: 0.5 * $default-thickness;
        color: $color-layout;
        width: 42px;
        height: 42px;
        font-size: 42px;
        line-height: 50px;
      }

      &-title {
        margin: 0 0 $default-thickness;
        padding: 0;
      }

      &-strapline {
        color: $color-gray-light;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .aside__list {
      padding: $aside-padding;
    }
  }
}

:host {
  .aside__head {
    display: flex;

    &-strapline {
      display: inline-block;
    }
  }
}
