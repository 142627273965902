@mixin purchase-requisition-actions-menu($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $secondary-black-color: mat-color($mp-gray, 100);

  .mpdm-purchase-requisition-actions-menu {
    .actions-menu-button {
      &.mat-stroked-button {
        border: 1px solid $secondary-black-color;
        background-color: transparent;
        text-align: center;
        padding: 0;
        min-width: 40px;
      }

      &__menu-icon {
        color: $color-gray;
      }
    }
  }
}
