@import 'variables';
@import 'mixins';

@mixin dashboard-widget($theme) {
  $color-gray: mat-color($mp-gray, 600);

  .mp-dashboard-widget {
    .mp-search-field {
      .mat-form-field {
        display: block;
      }

      .mat-form-field-infix {
        width: auto;
      }
    }

    .widget-header {
      border-bottom: 1px solid rgba($color-gray, 0.2);
      background-color: white;
      border-radius: 4px 4px 0 0;

      &__icon {
        color: $color-gray;
        line-height: 24px;

        svg path {
          fill: $color-gray;
        }
      }

      &__title {
        color: $color-gray;
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
      }
    }
  }
}

:host {
  display: block;

  .widget {
    padding: 0;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: $default-thickness * 0.5;
      height: 40px;

      &__icon {
        flex: 0 0 auto;
        margin: 0 $default-thickness * 0.5 0 $default-thickness * 0.5;
      }

      &__title {
        flex: 1 0 48px;
        margin: 0 $default-thickness 0 0;
        width: 0;
        display: none;

        @include breakpoint(sm) {
          display: block;
        }
      }

      &__search-field-container {
        flex: 1 1 auto;
        min-width: 0;

        @include breakpoint(sm) {
          flex-grow: 0;
        }
      }

      &__link-button {
        flex: 0 0 48px;
        min-width: 0;
        margin-left: $default-thickness * 0.5;
      }
    }

    &-content {
      padding: $default-thickness * 2;
    }
  }
}
