@import 'colors';
@import 'variables';

@mixin filter-input-options-global {
  $text-color: $color-layout-700;
  $light-gray: #989898;

  .mp-filter-input-options {
    .filter-input-card {
      mat-expansion-panel {
        .mat-expansion-panel-body {
          padding: 0 !important;
        }
      }

      .mat-expansion-panel-spacing {
        padding: 0 !important;
        background: transparent !important;
      }

      .mat-expansion-indicator::after {
        padding: 2px;
        color: $light-gray;
      }

      .card-content {
        .filter-section {
          &.expanded {
            .filter-section-body {
              // These stylings overrule the default .mp-filter-item styles:
              .mp-filter-item {
                padding: 0.25 * $default-thickness $default-thickness;
                margin: 0 -1 * $default-thickness;

                border-left: 4px $color-gray-50 solid;

                &:first-of-type {
                  margin-top: -0.25 * $default-thickness;
                }

                &.selected {
                  background-color: $color-accent-50;
                  border-left: 4px $color-accent-500 solid;
                }
              }
            }
          }
        }
      }
    }
  }
}
