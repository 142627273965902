@mixin add-to-cart-toast($theme) {
  $accent: map-get($mp-theme, accent);
  $accent-green: mat-color($accent, 500);

  .mpms-add-to-cart-toast {

    .article-name, .cost-center {
      color: $accent-green;
      @include mat-typography-level-to-styles($mp-typography, body-2);
    }
  }
}
