@mixin ms-deleted-favorites-list($theme) {
  $border-color: #c74141;

  .mpms-deleted-favorites-list {
    cdk-virtual-scroll-viewport {
      height: 100%;
      position: relative;
    }

    .article-list {
      &__article {
        border-bottom: 1px solid rgba(106, 106, 106, 0.25);
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        cursor: default;

        &:first-child {
          border-top: 1px solid rgba(106, 106, 106, 0.25);
        }

        &:last-of-type {
          border-bottom-width: 0;
        }
      }

      &__article-actions {
        .article-list__delete-article-button {
          border-color: $border-color;
        }
      }
    }
  }
}

:host {
  display: block;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .article-list {
    padding: 0;
    margin: 0;

    &__article {
      padding: 4px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-name {
        flex: 1 1 auto;
      }

      &-actions {
        flex: 0 1 auto;
      }
    }

    &__loading {
      display: flex;
      justify-content: center !important;
    }
  }
}
