@import 'variables';

@mixin export-items($theme) {
  $color-border: #e4e4e4;

  $color-gray: #757575;

  $color-background: white;

  .mpcm-export-items {
    .export-items-table {
      background: none;
      border-collapse: collapse;

      &__row {
        border: solid;
        border-width: 1px 0;
        border-color: $color-border;
      }

      &__header,
      &__cell {
        color: $color-gray;
      }

      &__header {
        font-weight: 700;
        font-size: 13px;
      }

      &__cell {
        font-weight: 300;
        font-size: 14px;

        &--name {
          background: $color-background;
        }
      }
    }
  }
}

:host {
  .export-items-table {
    $type-column-width: 75px;
    $user-column-width: 175px;
    $date-column-width: 125px;
    $actions-column-width: 65px;

    $total-column-width: $type-column-width + $user-column-width + $date-column-width + $actions-column-width;

    $name-column-width: max(100% - $total-column-width, 500px);

    &__row {
      margin: $default-thickness 0;
    }

    &__header,
    &__cell {
      text-align: left;
      padding: 0.5 * $default-thickness $default-thickness;
      box-sizing: border-box;
    }

    &__type-header {
      width: $type-column-width;
      text-align: center;
    }

    &__name-header {
      width: $name-column-width;
    }

    &__user-header {
      width: $user-column-width;
    }

    &__date-header {
      width: $date-column-width;
    }

    &__actions-header {
      width: $actions-column-width;
      padding-inline: 0;
    }

    &__panel-cell {
      padding-left: 0;
    }

    &__user-cell {
      word-break: break-word;
    }

    &__actions-cell {
      padding-inline: 0;
    }
  }
}
