@mixin match-proposals-table-global {
  .mpcm-match-proposals-table {
    .match-proposals-table {
      .article-image {
        .placeholder {
          background-color: transparent;
        }

        img {
          max-height: 64px;
        }
      }
    }
  }
}
