@import 'variables';

$font-mono: 'Courier New', Courier, monospace;

@mixin article-number-common {
  display: inline-block;
  font-size: 14px;

  .article-number {
    display: inline-flex;
    vertical-align: middle;
    width: 100%;
    line-height: 1.4em;

    &__icon {
      line-height: 1.85em;
      height: 1.4em;
      width: 1.7em;
      vertical-align: middle;
      margin-right: 0.25 * $default-thickness;
      flex: none;
    }

    &__value {
      flex: 0 1 auto;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      direction: rtl; // ellipsis to the left
    }
  }

  .font-mono {
    font-weight: 700;
    font-family: $font-mono;
  }

  .bold {
    font-weight: bold;
  }
}
