@import 'variables';
@import 'colors';

@mixin selected-category-tile($theme) {
  $color-gray: mat-color($mp-gray, 300);
  $color-light-gray: mat-color($mp-gray, 50);
  $color-dark-gray: mat-color($mp-gray, 600);

  $color-light-gold: $color-gold-50;

  $color-red: mat-color($mp-state, red);

  $font-mono: 700 #{14px}/#{20px} 'Courier New', Courier, monospace;

  .mpms-selected-category-tile {
    .selected-category {
      cursor: pointer;

      &--deleted {
        cursor: default;
      }

      &:hover {
        background-color: $color-light-gold;
      }

      &__clear,
      &__code,
      &__type {
        color: $color-gray;
      }

      &__title,
      &__code-text {
        color: $color-dark-gray;
      }

      &__title {
        text-align: center;
      }

      &__title,
      &__code {
        font-weight: bold;
        line-height: 18px;
        word-break: break-word;
      }

      &__code {
        background-color: $color-light-gray;
        text-align: center;
      }

      &__type {
        font-size: 14px;
      }

      &__clear {
        line-height: 22px;
        width: 22px;
        height: 22px;

        mat-icon {
          font-size: 20px;
        }

        &:hover,
        &:focus {
          color: $color-red;
        }
      }
    }

    .font-mono {
      font: $font-mono;
    }
  }
}

:host {
  .selected-category-tile {
    padding: 0;
    height: 100%;

    &__content {
      height: 100%;
    }
  }

  .selected-category {
    position: relative;
    padding: 2 * $default-thickness;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    flex-direction: column;
    gap: $default-thickness;

    &__clear {
      position: absolute;
      right: 0.5 * $default-thickness;
      top: 0.5 * $default-thickness;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__type {
      margin-top: 0.5 * $default-thickness;
    }
  }
}
