@import 'variables';

@mixin recent-requisition-item($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 100);

  .mpms-recent-requisition-item {
    .requisition-base-info {
      font-weight: bold;
      font-size: 14px;
      color: $color-gray;
    }

    .requisition-details {
      font-size: 14px;

      &__positions-count {
        .purchase-requisition-positions {
          &__count {
            font-size: 14px;
          }
        }
      }

      &__created-at {
        font-size: 14px;
        color: $color-gray;
      }
    }
  }
}

:host {
  display: block;

  .requisition-base-info,
  .requisition-details,
  .requisition-state {
    display: flex;
    align-items: center;
    gap: 0.5 * $default-thickness;
  }

  .requisition-base-info {
    &__name {
      flex: 1 1 60%;
    }

    &__creator {
      flex: 1 1 40%;
      text-align: right;
    }

    &__name,
    &__creator {
      min-width: 0;
    }
  }

  .requisition-details {
    &__requisition-state {
      flex: 1 1 60%;
    }

    &__created-at {
      flex: 1 1 40%;
      text-align: right;
    }

    &__requisition-state,
    &__created-at {
      min-width: 0;
    }
  }
}
