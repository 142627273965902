@mixin cm-deleted-favorites($theme) {
  $primary-color: #c74141;
  $border-color: #dfdfdf;
  $wrapper-color: #a7a7a7;
  $input-color: #757575;

  .mpcm-deleted-favorites {
    .deleted-favorites {
      color: $primary-color;

      &__header {
        font-weight: 700;
        font-size: 20px;
        line-height: 18px;
      }
    }

    .input-wrapper {
      background: white;
      border: 1px solid $border-color;
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      color: $wrapper-color;

      &__input {
        border: none;
        outline: none;
        color: $input-color;
        font-weight: 300;
      }
    }

    .delete-all-articles-button:not([disabled]) {
      border-color: $primary-color;
    }
  }
}

:host {
  .deleted-favorites {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 10px;

    &__header {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__bar {
      display: flex;
      justify-content: space-between;
    }

    &__input-wrapper {
      width: 300px;
      height: 40px;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    padding-left: 11px;

    &__search-icon {
      transform: scale(0.7);
      margin-right: 5px;
    }

    &__input {
      width: 100%;
    }
  }
}
