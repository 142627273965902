@import 'colors';

@import '../content-section.scss';

@mixin packungseinheiten-content-section {
  $font-mono: 'Courier New', Courier, monospace;

  .mpcm-packungseinheiten-content-section {
    background-color: white;
    display: block;

    @include content-section;

    .artikel-section-value {
      span + span {
        margin-left: 0.25 * $default-thickness;
      }

      .val__unit {
        font-size: 18px;
        line-height: 18px;
      }

      .val__factor {
        vertical-align: top;
        line-height: 18px;
      }

      .val__suffix {
        vertical-align: top;
        line-height: 18px;
        font-weight: 300;
        margin-left: 2px;
      }

      .val__gtn {
        font-family: $font-mono;
      }
    }

    .flex-column {
      display: flex;
      flex-direction: column;
    }

    .red {
      color: $color-state-red-500;
    }

    .green {
      color: $color-state-green-500;
    }

    .visibility-hidden {
      visibility: hidden;
    }
  }
}
