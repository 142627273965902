@mixin api-key-table-global {
  .mpk-api-key-table {
    .mat-form-field {
      width: 100%;
    }

    .mat-form-field-infix {
      width: 100%;
      padding: 0;
      border-top: 0;
    }

    .mat-form-field-wrapper {
      padding: 0;

      .mat-datepicker-toggle {
        position: absolute;
        right: 0;
        z-index: 9999;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .mat-form-field-flex {
        line-height: 38px;
      }

      .mat-form-field-underline {
        bottom: 2px;
      }
    }
  }
}
