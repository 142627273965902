@import 'colors';

@mixin upload-form($theme) {
  $color-error-background: $color-state-red-100;
  $color-error-foreground: $color-state-red-500;

  $color-success-background: $color-state-green-100;
  $color-success-foreground: $color-state-green-500;

  $color-upload-background: #fbfbfb;
  $color-upload-foreground: #dbdbdb;

  .mp-upload-form {
    .dropzone {
      background: #e3eced;

      &--success {
        background: $color-success-background;
        border: 2px dashed $color-success-foreground;
      }

      &--error {
        background: $color-error-background;
        border: 2px dashed $color-error-foreground;
      }

      &--upload {
        background: $color-upload-background;
        border: 2px dashed $color-upload-foreground;
      }

      .uploading-indicator {
        padding: 0 20px;
        width: 100%;
      }
    }
  }
}
:host {
  .dropzone {
    &__content {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}
