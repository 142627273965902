@import 'variables';

@mixin add-favorites-list-button($theme) {
  $accent: map-get($theme, accent);

  $accent-color-light: mat-color($accent, 50);
  $accent-green: mat-color($accent, 500);

  .mpcm-add-favorites-list-button {
    background-color: #fff;

    &--embedded {
      .add-list-button {
        background-color: $accent-color-light;
      }
    }

    .add-list-button {
      border-color: $accent-green !important;

      &__icon.mat-icon,
      &__label {
        color: $accent-green;
      }
    }
  }
}

:host {
  display: block;

  &.mpcm-add-favorites-list-button--embedded {
    .add-list-button {
      height: 50px;
    }
  }

  .add-list-button {
    width: 100%;
    height: initial;
  }
}
