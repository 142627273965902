@mixin favorites-articles($theme) {
  $accent: map-get($theme, accent);
  $color-accent: mat-color($accent, 500);

  $layout: map-get($mp-theme, layout);
  $background: map-get($theme, background);
  $row-hover-background: mat-color($background, hover);
  $light-blue: mat-color($layout, 50);
  $color-layout: mat-color($layout, 500);
  $color-title: mat-color($layout, 500);

  $color-gray-light: #a6a6a6;
  $color-gray: #757575;
  $aside-padding: 0 24px 0 16px;

  mp-favorites-articles {
    display: block;

    mat-card {
      height: 100%;
      padding: 0 !important;
      overflow: hidden;

      display: flex;
      flex-direction: column;
    }

    .table-container {
      flex: 1;
      overflow: auto;

      background-color: #fafafa;

      table {
        flex: 1;

        @include breakpoint(lg) {
          table-layout: fixed;
        }

        .article-image {
          --image-placeholder-background-color: transparent;
        }

        .supplierName {
          line-height: 48px;

          mat-icon {
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 20px;
          }
        }

        .mat-header-cell {
          padding-right: $default-thickness * 0.5;
        }

        .row--no-data {
          text-align: center;
          td {
            padding: $default-thickness * 4 0;
          }
          .no-data {
            font-size: 14px;
            font-weight: bold;
            color: $color-gray-light;
          }
        }

        tr {
          td.mat-cell,
          th {
            padding-right: 8px;
            padding-left: 16px;
          }

          th.mat-column-pictures {
            border-right: 1px $light-blue solid;
            width: 80px;
            box-sizing: border-box;
            min-width: 80px;
            text-align: center;
          }

          td.mat-column-pictures {
            background: #fff;
            border-right: 1px $light-blue solid;
            width: 80px;
            height: 80px;
            padding: 0;

            mp-image-gallery-viewer img {
              max-height: 80px;
            }
          }

          td.mat-column-name {
            color: $color-title;
          }

          .mat-column-name {
            font-weight: 700;
            width: 80%;
            max-width: 400px;

            @include breakpoint(lg) {
              width: 30%;
            }
          }

          .mat-column-type {
            width: 48px !important;

            mp-article-type-icon {
              color: rgba(0, 0, 0, 0.3);
              height: 48px;
              width: 48px;
              margin: 0;
            }
          }

          .mat-column-actions {
            width: 90px !important;
          }

          &.mat-row:hover {
            background: $row-hover-background;
            transition: $default-transition;
            cursor: pointer;

            .mat-column-actions button {
              opacity: 1;
            }
          }
        }
      }
    }

    .fav__head {
      margin: 0 0 $default-thickness 0;
      .fav__head-title {
        color: $color-layout;
        margin: 0;
        padding: 0;
      }
      .result__label {
        color: $color-gray;
        font-weight: bold;
        margin-right: 4px;
        font-size: 14px;
        line-height: 14px;
      }
      .result__count {
        color: $color-gray;
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
      }
    }

    mat-paginator {
      flex: 0;
      border-top: 1px solid $light-blue;
    }
  }
}
