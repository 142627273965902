@mixin article-details-summary-global {
  .mpms-article-details-summary {
    .mpad-summary {
      mp-image-gallery-viewer.mpad-summary__image {
        .placeholder {
          min-width: 200px;
          min-height: 200px;
        }
      }
    }
  }
}
