@mixin list-count-title($theme) {
  $accent: map-get($theme, accent);
  $color-accent: mat-color($accent, 500);

  $color-gray: #dedede;
  $color-dark-gray: mat-color($mp-gray, 600);
  $color-dark-light: #b3b3b3; // disabled

  $font-subtitle: 600 #{12px} 'Roboto';

  .mp-list-count-title {
    &.hasItems {
      .title-row {
        color: $color-dark-gray;
        margin-bottom: 8px;
      }
    }

    .title-row {
      display: flex;
      align-items: baseline;

      margin: 0;
      padding: 0;
      color: $color-dark-light;

      .title-col-count {
        flex: 0;
        text-align: center;

        span {
          height: 18px;

          padding: 0 8px;
          margin-right: $default-thickness;

          border-radius: 2px;

          font: $font-subtitle;
          line-height: 18px;

          color: inherit;
          background-color: rgb(229, 229, 229);
        }
      }

      .title-col-label {
        flex: 1;

        h5 {
          margin: 0;

          font: $font-subtitle;
          letter-spacing: 0.025em;
          text-transform: uppercase;

          color: inherit;
        }
      }
    }
  }

  .dialog-sidebar-body-matches {
    mp-list-count-title.hasItems .title-row .title-col-count span {
      background-color: $color-accent;
      color: #fff;
    }
  }
}
