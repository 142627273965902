@import 'colors';
@import 'variables';

@mixin param-global {
  .mp-param {
    .param-value {
      &.mat-form-field {
        .mat-form-field-wrapper {
          padding-bottom: 0.5 * $default-thickness;
        }

        .mat-form-field-infix {
          border-top: none;
          padding: 10px 0;
        }

        .mat-form-field-suffix {
          top: 0;
        }

        .mat-form-field-outline {
          top: 0;

          .mat-form-field-outline-start,
          .mat-form-field-outline-gap,
          .mat-form-field-outline-end {
            color: $color-gray-100;
            background-color: white;
          }
        }
      }
    }

    &.mp-param--inline {
      display: inline-flex;

      .param-value {
        &.mat-form-field {
          .mat-form-field-wrapper {
            padding-bottom: 0;
          }

          .mat-form-field-flex {
            margin-top: 0;
          }
        }
      }
    }
  }
}
