@import 'variables';

@mixin article-item-unit-price-theme($theme) {
  $color-gray: mat-color($mp-gray, 500);

  .mpdm-article-item-unit-price {
    .item-unit-price {
      color: $color-gray;

      &__price-label {
        font-weight: normal;
      }

      &__price {
        font-weight: bold;
      }
    }
  }
}

:host {
  display: inline-block;

  .item-unit-price {
    font-size: 13px;
    line-height: 18px;

    &__price {
      padding-right: 2px;
    }
  }
}
