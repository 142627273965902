@import 'variables';
@import 'colors';

@mixin article-vertical-tile($theme) {
  $layout: map-get($theme, layout);
  $primary: map-get($theme, primary);

  $color-card-bg-hover: $color-gold-25;

  .mpms-article-vertical-tile {
    transition: background-color 150ms ease-in-out;
    border-radius: 1px;

    &:hover {
      background-color: $color-card-bg-hover;
    }

    .article-image-wrapper {
      &__article-image {
        --image-placeholder-background-color: transparent;
      }
    }

    .article-head-info {
      &__article-name {
        cursor: pointer;
      }
    }

    .placeholder-text {
      font-size: 13px;
      color: gray;
    }
  }
}

:host {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2 * $default-thickness 1.5 * $default-thickness;

  .article-image-wrapper {
    display: flex;
    justify-content: center;

    padding-inline: 2 * $default-thickness;

    &__article-image {
      max-width: 80%;
      height: 100px;
      flex: 0 0 auto;
    }
  }

  .article-favorites-menu {
    position: absolute;
    top: $default-thickness;
    right: 2 * $default-thickness;
  }

  .article-head-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    margin-top: $default-thickness;
    flex: 1;

    &__supplier-name,
    &__supplier-article-number {
      max-width: 100%;
    }
  }

  .article-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin-top: 0.5 * $default-thickness;

    &__actions {
      flex: 0 0 40px;
    }
  }
}
