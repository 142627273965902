@mixin mp-toast($theme) {
  $color-green: mat-color($mp-state, green);
  $color-yellow: mat-color($mp-state, yellow);
  $color-orange: mat-color($mp-state, orange);
  $color-red: mat-color($mp-state, red);

  .mp-toast-warning {
    color: #fff;
    background-color: $color-red;

    .mat-simple-snackbar-action {
      color: white;
    }
  }

  .mp-toast-success {
    background-color: $color-green;
    color: #fff;
  }
}
