@import 'variables';
@import 'colors';

@mixin file-info-dialog($theme) {
  $accent: map-get($theme, 'accent');
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 100);

  $bg-light-gray-color: mat-color($mp-gray, 50);

  $color-focus: $color-gold-25;
  $color-accent: mat-color($accent, 500);

  .mpcm-file-info-dialog-container {
    .mat-dialog-container {
      background-color: white;
      max-width: 80vw;
    }

    .mat-radio-label-content {
      display: none;
    }

    .mat-form-field-label {
      margin-left: 0.5 * $default-thickness;
    }

    .dialog-title {
      color: $color-gray;
      text-align: center;
      margin-bottom: $default-thickness * 2;
    }

    .mat-form-field.dialog-content {
      &__comment {
        margin: 0;

        textarea {
          background-color: $bg-light-gray-color;
          border-radius: 4px 4px 0 0;
          padding: $default-thickness;
          box-sizing: border-box;
        }

        label.mat-form-field-label {
          color: $color-gray;
          font-size: 14px;
          line-height: 40px;
        }
      }
    }

    .option {
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: $color-focus;
      }

      .option__label.option__label--active {
        color: $color-accent;
        user-select: none;
      }

      &__label {
        color: $color-gray;
        font-weight: bold;
        font-size: 14px;
      }

      &:not(.option--selected) {
        .option__radio-button {
          .mat-radio-outer-circle {
            border-color: $color-light-gray;
          }
        }
      }
    }
  }
}

:host {
  display: block;
  padding: 32px 32px 16px;

  .dialog-content {
    &__options {
      display: flex;
    }
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: $default-thickness 4px;
    gap: 4px;
    min-width: 0;

    &__label {
      max-width: 100%;
    }

    .mpcm-file-type-tile {
      margin-bottom: $default-thickness * 0.5;
    }
  }

  .dialog-submit {
    flex-grow: 1;
  }
}
