@import 'mixins';
@import 'colors';

@import '../platformcatalog-panel-common';

@mixin plattformkataloge-list($theme) {
  $text-gray-dark: #444;

  .mp-plattformkataloge-list .mp-page__content {
    .page-list-header {
      display: flex;
      align-items: center;
      gap: $default-thickness;

      &__action-buttons {
        button {
          margin-left: $default-thickness;

          mat-icon {
            padding-right: $default-thickness * 0.5;
            @include icon-size(20px);
          }
        }
      }
    }

    .mat-expansion-panel-spacing {
      margin: $default-thickness * 2 0;
    }

    .platformcatalog-panel {
      @include platformcatalog-panel-common;

      .header {
        .title-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: $default-thickness * 2;

          mat-panel-title {
            flex: 1 0 auto;
            display: flex;
            color: $text-gray-dark;

            .header-title {
              display: flex;
              width: 100%;
              line-height: 0;
              vertical-align: middle;

              .header-icon {
                padding-right: $default-thickness;
                vertical-align: middle;
              }

              .header-name {
                display: inline-block;
                margin: 0;
                padding: 0;
                vertical-align: middle;
                width: 0;
                flex-grow: 1;
              }

              .fehlermeldung {
                padding-left: $default-thickness;
                color: $color-state-red-500;
                font-size: 14px;
                line-height: 20px;
                display: block;
                text-align: left;
                padding: 0;
              }

              &.header-title--error {
                color: $color-state-red-500;
              }

              .header-badge {
                width: 0;
                flex-grow: 1;
              }
            }
          }

          mat-panel-description {
            flex: 0 1 auto;

            .update-col {
              background-color: $color-state-orange-200;
              color: $color-state-orange-500;
              padding: 0 8px;
              font-size: 14px;
              line-height: 24px;
              margin: 0;
              top: 3px;
              position: relative;
              border-radius: 2px 0 0 2px;

              &:after {
                content: '';
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 18px 0 18px 16px;
                border-color: transparent transparent transparent $color-state-orange-200;
                right: -10px;
                top: -6px;
              }
            }

            .action-col {
              display: flex;
              align-items: center;
              margin-left: $default-thickness;
              row-gap: 0.5 * $default-thickness;
              flex-direction: column;

              @include breakpoint(xl) {
                flex-direction: row;
              }
            }

            .date-col {
              text-align: end;
              padding-right: $default-thickness;
              margin-left: 20px;

              .header-date {
                font-size: 14px;
                font-weight: 300;
                color: $color-gray-300;
                padding: 0;
                margin: 0;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
