@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin slider-overrides {
  .mat-list-single-selected-option {
    &,
    &:hover,
    &:focus {
      background: color-utils.add-opacity-to-color-variable($color-background-hover, 0.12);
    }
  }
}
