@mixin show-all-link($theme) {
  $accent: map-get($theme, accent);

  $color-accent: mat-color($accent, 600);

  $color-light-gray: mat-color($mp-gray, 300);

  .mpms-show-all-link {
    .show-all-link {
      text-decoration: none;

      &__label {
        color: $color-light-gray;
        font-size: 13px;
        font-weight: bold;
      }

      &__icon {
        color: $color-accent;

        &--disabled {
          color: $color-light-gray;
        }
      }
    }

    &:hover .show-all-link .show-all-link__label {
      color: $color-accent;
    }
  }
}

:host {
  display: block;

  .show-all-link {
    display: flex;
    align-items: center;
  }
}
