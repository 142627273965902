@import 'colors';

@mixin _select-option-focus-colors($background) {
  &.mat-active,
  &:focus {
    color: $option-active-color;
    background-color: $background;
  }
}

@mixin option-default-styles {
  mat-option.mat-option {
    color: $option-color;

    height: fit-content;
    min-height: 48px;
    line-height: normal;

    @include _select-option-focus-colors(inherit);

    &:nth-child(even) {
      background-color: $option-background-color;

      &:not(.mat-selected) {
        @include _select-option-focus-colors($option-background-color);
      }
    }

    &.mat-selected {
      color: $option-active-color !important;
    }

    &.mat-option-disabled {
      pointer-events: none;
      opacity: 0.6;
    }
  }
}
