@import 'colors';
@import 'variables';

@mixin medical-shop-header-global {
  .mpms-medical-shop-header {
    &__shopping-cart-nav-element {
      color: red;
      // Transition is modified here to match the nav-element color transition
      --shopping-cart-nav-item-badge-border-color-transition: #{$default-transition};

      &:hover,
      &--active {
        // NOTE - we need to use a color-mix() here instead of rgba() as resulting badge border color cannot have alpha channel
        $non-transparent-badge-border-color: color-mix(
          in srgb,
          $color-background-app-bar,
          $color-app-header-element-base-color $color-app-header-elements-hover-alpha * 100%
        );
        --shopping-cart-nav-item-badge-border-color: #{$non-transparent-badge-border-color};
      }
    }

    &__search-bar {
      max-width: $header-searchbar-max-width;
    }
  }
}
