@mixin purchase-requisition-positions-count($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $color-green: mat-color($mp-state, green);

  .mpdm-purchase-requisition-positions-count {
    .purchase-requisition-positions {
      color: $color-gray;

      &__label,
      &__count {
        display: block;
        font-size: 16px;
        line-height: 20px;
        font-weight: normal;
      }

      &__label {
        font-size: 13px;
      }

      &__count--approved,
      &__count--not-approved {
        font-weight: bold;
      }

      &__count--not-approved {
        color: $color-green;
      }
    }
  }
}
