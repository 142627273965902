@mixin selector-element-global {
  .mp-selector-element {
    .selector-element {
      .mat-checkbox-layout {
        max-width: 100%;
      }

      .mat-checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .mat-checkbox-inner-container {
        margin-top: 4px;
      }
    }
  }
}
