@import 'colors';
@import 'variables';

@mixin entity-autocomplete-global {
  .mp-entity-autocomplete {
    &__autocomplete-panel {
      .no-entries {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 76px;
        font-size: 14px;
        color: $color-gray-300;
      }

      .entity-autocomplete-item {
        padding: $default-thickness 1.5 * $default-thickness $default-thickness 0;

        h4 {
          margin: 0 0 4px;
          font-size: 18px;
          line-height: 20px;

          max-width: 100%;
        }

        span {
          margin: 0;
          font-size: 14px;
          line-height: 16px;

          max-width: 100%;
        }
      }
    }
  }
}
