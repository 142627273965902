@mixin user-error-details($theme) {
  $layout: map-get($theme, layout);

  $color-primary: mat-color($layout, 500);

  $color-gray: mat-color($mp-gray, 500);

  .mpe-user-error-details {
    .error-reason {
      color: $color-primary;
      font-size: 32px;
      line-height: 40px;
      font-weight: bold;
    }

    .help-info {
      margin-block: 25px;
      color: $color-gray;
    }
  }
}
