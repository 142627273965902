@import 'colors';

$font-mono: 'Courier New', Courier, monospace;

@mixin requisition-positions-table-theme($theme) {
  .mpdm-requisition-positions-table {
    --table-first-cell-left-padding: #{$table-cell-padding};

    mat-paginator {
      margin-right: $default-thickness * 1.5;
      color: $color-gray-600;
    }

    mpdm-article-item-unit {
      text-align: center;
    }

    .requisition-positions-table__loading-indicator {
      padding: $default-thickness 0;
    }

    .requisition-positions-table__row {
      height: 90px;

      &--rejected {
        > :not(.mat-column-articleApproval) * {
          opacity: 0.8;
          color: $color-gray-400;
        }
      }
    }

    .requisition-positions-table__header {
      border-top: 1px $table-border-color solid;
    }

    .requisition-positions-table__cell {
      &.mat-column-articleApproval,
      &.mat-column-articleImage {
        background-color: white;
        border-right: 1px $table-border-color solid;
      }
    }

    .mat-column-articleApproval {
      padding-left: $table-cell-padding;

      .mat-checkbox-inner-container {
        width: 18px;
        height: 18px;
      }

      .article-approved-icon {
        color: $color-state-green-500;
      }

      .article-declined-icon {
        color: $color-state-red-500;
      }
    }

    .mat-column-articleApproval,
    .mat-column-articleImage {
      flex: 0 0 90px;
      height: 100%;
    }

    .mat-column-articleImage {
      .mpcm-article-image {
        --image-placeholder-background-color: transparent;
      }
    }

    .mat-column-articleName {
      mpdm-article-item-details {
        width: 100%;
      }
    }

    .mat-column-articleQuantity {
      flex: 0 0 15%;
      min-width: 130px;
    }

    .mat-column-articleUnit {
      flex: 0 0 15%;
      text-align: center;
    }

    .mat-column-articlePrice {
      flex: 0 0 20%;
    }
  }
}
