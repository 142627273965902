@mixin cart-deleted-positions($theme) {
  $layout: map-get($theme, layout);

  $primary-red-color: mat-color($mp-state, red);
  $bg-color: #FFFBFB;
  $odd-bg-color: #FFF7F7;
  $border-color: #FFE5E5;
  $button-text-color: white;

  .mpms-cart-deleted-positions {
    border-top: 1px solid $border-color;

    .del-pos__item {
      color: $primary-red-color;
      padding: 0.5*$default-thickness 2*$default-thickness;
      background-color: $bg-color;
      border-bottom: 1px solid $border-color;

      &:nth-child(odd) {
        background-color: $odd-bg-color;
      }

      &:last-child {
        border-bottom: 0;
      }

      &-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        padding: 0;
      }

      &-button {
        background-color: $primary-red-color;
        color: $button-text-color;
      }
    }
  }
}

:host {
  display: block;

  .del-pos {
    list-style: none;
    overflow-y: auto;
    max-height: 130px;
    padding: 0;
    margin: 0;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
