@mixin barcode-protokoll($theme) {
  $color-gray: mat-color($mp-gray, 600);
  .mp-barcode-protokoll {
    display: flex;
    flex-direction: column;
    margin: $default-thickness;
    padding: $default-thickness;

    .barcode-protocol-header {
      margin-bottom: $default-thickness;

      &__button {
        margin-left: 0.5 * $default-thickness;
        color: $color-gray;
      }
    }

    .protokoll-cell {
      padding-right: $default-thickness * 2;
    }

    .amount {
      text-align: right;
      padding-right: $default-thickness * 2;
    }

    .delete-button-icon {
      color: $color-gray;
    }
  }
}

:host {
  .barcode-protocol-header {
    display: flex;
    align-items: center;

    &__text {
      margin: 0;
    }
  }
}
