@import 'variables';
@import 'colors';

@mixin category-selection-tile($theme) {
  $accent: map-get($theme, accent);

  $color-accent: mat-color($accent, 600);
  $color-gray: mat-color($mp-gray, 300);
  $color-light-gray: mat-color($mp-gray, 50);
  $color-dark-gray: mat-color($mp-gray, 700);
  $color-light-gold: $color-gold-50;

  .mpms-category-selection-tile {
    .category-selection {
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: $color-light-gold;

        mat-icon.category-selection__category-icon {
          color: $color-dark-gray;
          transition: color 0.2s ease-in-out;
        }

        .category-selection__add-category {
          color: $color-accent;
        }
      }

      // Styling for the cut corner
      &::before {
        content: '';
        border-bottom: 42px solid $color-light-gray;
        border-left: 46px solid transparent;
      }

      &__category-icon {
        color: $color-gray;
        font-size: 44px;
        width: 44px;
        height: 44px;
      }

      &__add-category {
        color: $color-gray;
        line-height: 22px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

:host {
  .category-selection-tile {
    padding: 0;
    height: 100%;

    &__content {
      height: 100%;
    }
  }

  .category-selection {
    position: relative;
    padding: 2 * $default-thickness;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100%;

    // Cut corner positioning
    &::before {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 0;
    }

    &__add-category {
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
