@import 'variables';

@mixin dropdown-global {
  .mp-dropdown {
    .dropdown-form-field {
      .mat-form-field-flex {
        padding-top: 0.5em;
      }

      .mat-form-field-prefix,
      .mat-form-field-suffix {
        .mat-icon {
          line-height: normal;
        }
      }

      &.mat-focused {
        .mat-form-field-required-marker {
          color: inherit;
        }
      }

      &--standard-appearance {
        .mat-form-field-flex {
          padding: 0;
          background: none;
        }
      }

      &--inline {
        .mat-form-field-flex {
          padding-top: 0;
          align-items: center;
        }

        // This value is used to counter the padding-bottom with the same value
        .mat-form-field-wrapper {
          margin-bottom: -1.625em;
        }

        .mat-form-field-infix {
          border-top-width: 0.5em;
        }

        .mat-select-arrow-wrapper {
          transform: none;
        }
      }
    }
  }
}
