@import 'colors';

@mixin filter-search-field-global {
  .mp-filter-search-field {
    .search-field {
      .mat-form-field-infix {
        border-top: none;
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-underline {
        display: none;
      }

      .mat-form-field-label {
        color: $color-gray-600;
        font-size: 14px;
        line-height: 34px;
      }

      .mat-input-element {
        color: $color-gray-900;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}
