@mixin mp-nav-item($theme) {
  $layout: map-get($theme, layout);
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);

  $item-normal-foreground: mat-color($layout, 500);
  $item-active-background: mat-color($primary, 50);
  $item-active-group-background: mat-color($layout, 100);
  $item-active-group-bar-background: mat-color($layout, 200);
  $item-expanded-background: mat-color($layout, 100);
  $item-disabled-foreground: mat-color($foreground, disabled-button);

  $bar-color-expanded: mat-color($layout, 300);
  $bar-color-active: mat-color($primary, 400);

  .mp-nav-item {
    display: block;
    @include mat-typography-level-to-styles($mp-typography, subheading-2);

    position: relative;

    .link {
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      flex: 1;
      height: $default-toolbar-height;
      transition: $default-transition;
      color: $item-normal-foreground;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background: $item-active-group-bar-background;
        border-radius: 0;
        transition: width 280ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        transition: $default-transition;
        color: $item-normal-foreground;
        background: $item-active-background;

        &:before {
          width: 3px;
          background-color: $bar-color-active;
        }
      }

      &:visited {
        color: $item-normal-foreground;
        transition: $default-transition;
      }

      &:focus {
        color: $item-normal-foreground;
        transition: $default-transition;
      }

      &.active {
        color: $item-normal-foreground;
        background: $item-active-background;
        transition: $default-transition;
        @include mat-typography-level-to-styles($mp-typography, subheading-1);

        &:before {
          width: 3px;
          background-color: $bar-color-active;
        }
      }

      &.expanded {
        color: $item-normal-foreground;
        background: $item-active-group-background;
        transition: $default-transition;
        @include mat-typography-level-to-styles($mp-typography, subheading-1);

        &:before {
          width: 3px;
          background-color: $bar-color-expanded;
        }
      }

      .child-item-line {
        border-bottom: 2px solid $color-gray-200;
        width: $default-thickness;
      }

      .icon {
        padding-inline: $navigation-item-padding;
      }
    }

    .group-label {
      display: none;
    }
    .group-content {
      background: $item-expanded-background;

      mp-nav-item a {
        &.active:before {
          width: 3px;
          background: $bar-color-active;
        }
      }
    }
  }
}
