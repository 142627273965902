@import 'colors';
@import 'variables';

@mixin change-password-input-global {
  .mp-change-password-input {
    &__requirements {
      // Additional offset to ensure that form-field underline is also visible
      margin-top: 4px;
      width: 100%;
      box-shadow: $default-box-shadow;
      background-color: white;

      .requirement {
        cursor: default;

        &__row {
          display: flex;
          align-items: center;

          color: $color-state-red-500;

          &-icon {
            flex: 0;
          }

          &-label {
            flex: 1;
          }

          &--fulfilled {
            color: $color-state-green-500;
          }
        }
      }
    }
  }
}
