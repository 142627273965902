@import 'colors';

@mixin artikel-match-group {
  $color-light-gray: #f5f5f5;
  $color-gray: #dedede;
  $color-medium-gray: #898989;

  $font-title: 700 #{14px}/#{18px} 'Roboto';
  $font-meta: 300 #{13px}/#{18px} 'Roboto';

  .mpcm-artikel-match-group {
    display: block;

    .article-match-group {
      &__radio {
        width: 100%;

        .mat-radio-label {
          padding: 0.5 * $default-thickness 0.5 * $default-thickness 0.5 * $default-thickness 0.75 * $default-thickness;
          box-sizing: border-box;
        }

        .mat-radio-label-content {
          min-width: 0;
          display: flex;
          width: 100%;
          padding-left: 10px;
        }

        &:not(.mat-radio-checked) {
          .mat-radio-outer-circle {
            border-color: $color-medium-gray;
          }
        }

        border-left: 3px solid transparent;
        border-top-left-radius: 2px;

        &.is-proposal {
          border-left-color: $color-state-orange-500;
        }

        &.is-match {
          border-left-color: $color-state-green-500;
        }
      }

      // Main group
      &__content {
        box-shadow: $card-box-shadow;
        background-color: white;

        border-radius: 4px;
        border: 2px solid $color-light-gray;

        cursor: pointer;

        &.active {
          border-color: $color-primary-100;

          .article-match-group__radio.mat-radio-checked {
            background-color: rgba($color-primary-500, 0.1);
          }
        }

        &-actions {
          display: flex;
          flex-direction: row;
          justify-content: center;
          color: $color-gray-600;

          &__button {
            width: 100%;
          }
          &__icon {
            color: $color-accent-500;
            margin-right: 0.5 * $default-thickness;
          }
        }
      }

      // Sup group
      &__platform-articles {
        > .article-match-group__radio {
          border-top: 1px solid $color-gray;

          .mat-radio-label {
            padding-left: 2 * $default-thickness;
          }
        }

        .article-match-group__score-col {
          min-width: 24px;
          text-align: right;
        }

        &__title {
          border-top: 1px solid $color-gray;
          background-color: $color-light-gray;
          color: $color-medium-gray;
          padding: 2px 0.5 * $default-thickness 2px 2 * $default-thickness;
          font: $font-title;
          font-size: 13px;
        }
      }

      // Row
      &__content-row {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        width: 100%;
        gap: 8px;
      }

      // Content column
      &__content-col {
        flex: 1;
        min-width: 0;
        color: $color-gray-600;

        &__title {
          display: block;
          font: $font-title;
        }

        &__infos {
          display: flex;
          align-items: center;
          gap: 4px;
          font: $font-meta;

          > span {
            display: block;
            line-height: 18px;
          }

          &__supplier,
          &__article-name {
            flex: 0 1 auto;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &__article-number {
            max-width: 100px;
            font-size: 12px;
          }
        }
      }

      // Score column
      &__score-col {
        flex: none;
      }

      // Action column
      &__action-col {
        flex: none;
        align-self: baseline;
      }

      &__score {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 0 3px;
        border-radius: 1px;

        &--green {
          color: #5ac040;
          background: #f2ffef;
        }
        &--red {
          color: #d71616;
          background: #ffe2e7;
        }
      }
    }
  }
}
