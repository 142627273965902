$font-mono: 'Courier New', Courier, monospace;

@mixin hitlists-list($theme) {
  $accent: map-get($theme, accent);
  $color-accent: mat-color($accent, 500);

  $color-gray: #757575;
  $background-color: #f6f6f6;

  .mpms-hitlists-list {
    .hitlist-item {
      color: $color-gray;
      background: $background-color;
      margin: 0.5 * $default-thickness 0;
      padding: 0.5 * $default-thickness $default-thickness 0;
      font-weight: 700;
      cursor: pointer;

      &.active {
        color: $color-accent;
      }
    }

    .font-mono {
      font-size: 16px;
      font-weight: 700;
      font-family: $font-mono;
    }
  }
}

:host {
  .hitlist-item {
    display: flex;
    flex-direction: column;
  }
}
