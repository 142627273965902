@mixin organization-info($theme) {
  $gray: map-get($mp-gray, 400);

  .mp-organization-info {
    padding: 4 * $default-thickness;
    display: flex;
    flex-direction: column;

    @include breakpoint(sm) {
      flex-direction: row;
    }

    .organization-col,
    .clinic-catalog-col {
      width: 100%;

      @include breakpoint(sm) {
        width: 50%;
      }

      small {
        color: $gray;
      }
    }

    .organization-col {
      flex: 1;
    }

    .clinic-catalog-col {
      flex: 2;
      margin: 4 * $default-thickness 0 0;

      @include breakpoint(sm) {
        margin: 0;
      }
    }
  }
}
