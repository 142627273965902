@import 'mixins';
@import 'variables';

@mixin article-ranges-search-field-global {
  .mpcm-article-ranges-search-field {
    .search-field-form-field {
      &.mat-form-field-appearance-fill {
        .mat-form-field-wrapper {
          padding: 0;
        }

        .mat-form-field-flex {
          background-color: transparent;
          line-height: 24px;
          align-items: center;

          padding: 0.5em;
        }

        input.mat-input-element {
          &::placeholder {
            font-weight: normal;
            color: inherit;
          }
        }

        .mat-form-field-infix,
        input.mat-input-element {
          margin: 0;
          padding: 0;
          border: 0;
        }

        .mat-form-field-underline,
        .mat-form-field-label-wrapper,
        .mat-form-field-subscript-wrapper {
          display: none;
        }
      }
    }
  }
}
