@mixin entity-list-head($theme) {
  $font-label: 700 #{14px}/#{32px} Roboto;
  $font-count: 700 #{14px}/#{32px} Roboto;

  $color-gray: #757575;
  $button-border-color: map-get($mp-gray, 50);
  $button-text-color: map-get($mp-gray, 600);
  $input-border-radius: 3px;

  $color-green: mat-color($mp-state, green);
  $color-yellow: mat-color($mp-state, yellow);
  $color-orange: mat-color($mp-state, orange);
  $color-red: mat-color($mp-state, red);

  .mp-entity-list-head {
    display: flex;
    color: $color-gray;
    font: $font-label;
    margin-bottom: $default-thickness;

    .entity-list-head__label {
      flex: 1 1 auto;
      line-height: 34px;
      min-width: 0;
      padding: 4px 8px 0 0;

      .entity-list-head__count {
        font: $font-count;
        margin-right: $default-thickness * 0.25;
      }
    }

    .entity-list-head__collapse-btn {
      margin-right: $default-thickness * 0.5;
    }

    .entity-list-head__actions {
      flex: 0 1 auto;
      display: flex;
      min-width: 0;

      .entity-list-head__action-btn {
        display: block;
        height: 34px;
        flex: 0 0 auto;
        min-width: 0;
        padding: 0;
        box-sizing: border-box;

        &:last-child {
          padding-right: 0;
        }
      }

      mp-search-field .mat-form-field-outline {
        height: 34px;
      }

      button.mat-flat-button {
        background: #fff;
        border: 1px $button-border-color solid;
        color: $button-text-color;
        line-height: 32px;
        border-bottom: 1px rgba(0, 0, 0, 0.15) solid;
        margin-right: $default-thickness * 0.5;

        mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }

      button.mat-stroked-button {
        border: 1px $button-border-color solid;
        color: $button-text-color;
        line-height: 32px;
        box-sizing: content-box;

        mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
        }
      }

      button + button {
        margin-left: $default-thickness * 0.5;
      }

      .btn--update-all:not(.mat-button-disabled) {
        color: $color-orange;
        border-color: currentColor;
      }

      .entity-list-head__search-form {
        flex: 0 1 auto;
        min-width: 0;
      }

      .entity-list-head__add-btn {
        height: 36px;
        flex: 0 1 auto;
        margin-left: $default-thickness * 0.5;
      }

      .mat-form-field-wrapper {
        margin: 0;
        padding: 0;
        .mat-icon-button {
          height: auto !important;
        }
      }

      .mp-search-field .wrapper .search-field-col > mat-form-field .mat-form-field-flex > .mat-form-field-infix {
        border-top: 1.025em solid transparent !important;
        padding: 0px 0 4px 0 !important;
      }
      .mp-search-field {
        margin-right: $default-thickness * 0.5;
      }
    }
  }
}
