@import 'mixins';

@mixin news-item-edit-actions($theme) {
  $color-gray: mat-color($mp-gray, 500);

  .mp-news-item-edit-actions {
    .news-edit-actions__close-action-button,
    .news-edit-actions__delete-action-button {
      color: $color-gray;
    }
  }
}

:host {
  .news-edit-actions {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    gap: 4px;

    &__close-action-button,
    &__delete-action-button {
      min-width: 40px;
      padding: 0;
    }

    &__save-action-button-text {
      display: none;

      @include breakpoint(lg) {
        display: inline;
      }
    }
  }
}
