$full: 100%;
$default-thickness: 16px;
$default-toolbar-height: 56px;
$default-font-size: 24px;
$app-side-nav-width: 70px;
$app-page-aside-width: 360px;
$default-shadow-color: rgba(0, 0, 0, 0.15);
$default-box-shadow: 0px 3px 6px $default-shadow-color;
$default-border-radius: 4px;
$default-transition: all 0.15s ease-in-out;
$container-transition: all 0.3s ease-in-out;
$card-box-shadow:
  0 1px 1px 0 rgba(0, 0, 0, 0.15),
  0 0 2px 1px rgba(0, 0, 0, 0.05);
$header-searchbar-max-width: 580px;
$list-header-bottom-spacing: $default-thickness;
$list-header-context-filter-width: 240px;
$table-cell-padding: 0.5 * $default-thickness;
$table-row-min-height: 64px;
$navigation-item-padding: 24px;

$breakpoints: (
  xs: 420px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);
