@mixin task-info($theme) {
  $gray: mat-color($mp-gray, 600);
  $color-orange: mat-color($mp-state, orange);

  .mp-task-info {
    display: flex;
    align-items: center;
    margin-right: $default-thickness *1.5;

    .color-orange {
      color: $color-orange;
    }

    .color-gray {
      color: $gray;
    }

    span {
      font-weight: bold;
      margin: 0 1px 0 1px;
    }

    mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
      line-height: 20px;
      margin-right: 2px;
    }
  }
}
