@mixin order-article-button($theme) {
  $accent: map-get($theme, accent);

  $accent-color: mat-color($accent, 400);
  $accent-color-light: mat-color($accent, 50);

  $color-gray: #757575;
  $color-light-blue: #f3f7f7;

  .mpms-order-article-button {
    display: flex;
    flex: 0 1 50%;
    position: relative;

    &--simplified {
      flex: 1;

      .add-button {
        min-width: 0;
        padding: 0;

        &__label {
          display: none;
        }
      }
    }

    .add-button {
      flex: 1;
      color: $accent-color;
      background-color: $accent-color-light;
      align-self: flex-end;

      mat-icon {
        font-size: 20px;
        height: 20px;
        width: 20px;
        line-height: 20px;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      button {
        pointer-events: none;
      }
    }
  }

  button.mat-menu-item {
    height: 64px;
    &:nth-child(even):not(.new-cart-button) {
      background-color: $color-light-blue;
    }
  }

  button.mat-menu-item.new-cart-button,
  button.mat-menu-item.empty-cart-button {
    height: 40px;
  }

  .menu-item {
    display: block;
    color: $color-gray;
    font-weight: 700;
    line-height: 16px;
  }

  .costCenter {
    display: flex;

    .costCenter-name {
      line-height: 20px;
    }

    .costCenter-number {
      line-height: 20px;
      flex: 0 1 auto;
      min-width: 0;
      padding-right: 0.25 * $default-thickness;
    }
  }

  .costCenter-address {
    margin-top: 0.25 * $default-thickness;
    flex: 1 auto;
    min-width: 0;
    color: $color-gray;
  }
}
