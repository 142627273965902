@import 'colors';

@mixin article-details-tabs-global {
  $color-layout: $color-layout-500;

  .mpms-article-details-tabs {
    .mpad-tabs {
      .mat-tab-header {
        border-bottom: 1px solid $color-layout-50;

        .mat-ink-bar {
          background-color: $color-layout;
        }

        .mat-tab-label {
          opacity: 1;
          color: $color-gray-600;

          &.mat-tab-label-active {
            color: $color-layout;
          }

          &.mat-tab-disabled {
            color: $color-gray-100;
          }
        }
      }
    }
  }
}
