@import 'colors';

@mixin purchase-requisition-init-state($theme) {
  $color-gray: mat-color($mp-gray, 600);

  $primary-orange-color: $color-gold-450;
  $secondary-orange-color: $color-gold-50;

  .mpdm-purchase-requisition-init-state {
    .requisition-state__init-state {
      &-icon,
      &-time,
      &-label {
        color: $color-gray;
      }

      &-time {
        margin: 0 0 0 1.5 * $default-thickness;
      }

      .pending {
        color: $primary-orange-color;
        background-color: $secondary-orange-color;
      }
    }
  }
}

:host {
  .requisition-state__init-state {
    &-description {
      display: flex;
      align-items: center;
    }

    &-time,
    &-label {
      font-size: 12px;
      line-height: 16px;
    }

    &-label {
      padding: 2px;
    }

    &-time {
      display: block;
      font-weight: normal;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }
}
