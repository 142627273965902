@mixin entity-item($theme) {
  $accent: map-get($theme, accent);
  $layout: map-get($theme, layout);
  $accent-color: mat-color($accent, 500);
  $color-layout: mat-color($layout, 500);

  $item-border-radius: 4px;
  $item-remove-background: #a1a1a1;

  $color-text-gray: #757575;

  $item-border-radius: 4px;
  $item-background--removed: #a1a1a1;

  $font-label: 700 #{16px}/#{32px} Roboto;
  $font-meta: 300 #{14px}/#{30px} Roboto;

  $vgap: 12px;

  .mp-entity-item {
    display: block;
    background: #fff;
    border-radius: $item-border-radius;
    padding: 7px $default-thickness * 0.5 7px $default-thickness;
    margin: 0 0 $default-thickness * 0.5 0;
    box-sizing: border-box;

    border: 1px rgb(0 0 0 / 5%) solid;
    border-bottom: 1px rgb(0 0 0 / 15%) solid;

    display: flex;
    flex-wrap: nowrap;

    .entity-item__icon {
      flex: 0 0 20px;
      height: 32px;
      line-height: 28px;
      color: $color-layout;
      margin-right: $vgap;
      min-width: 0;

      .mat-icon {
        font-size: 20px;
        height: 20px;
        width: 20px;
        vertical-align: middle;
      }
    }

    .entity-item__label {
      flex: 0 1 auto;
      display: flex;
      white-space: nowrap;
      min-width: 0;
      width: 100%;

      &-text {
        font: $font-label;
        color: $color-layout;
        margin: 0;
        padding: 0;
        margin-right: $vgap;
      }

      &-meta {
        font: $font-meta;
        color: $color-text-gray;
        padding-top: 2px;

        mat-chip-list {
          display: block;
          margin-top: 2px;

          mat-chip {
            font-size: 14px;
            color: $color-text-gray;
            min-height: 24px;
          }
        }
      }
    }

    .entity-item__actions {
      display: flex;
      align-items: center;
      flex: 0 0 auto;

      .mat-icon-button {
        line-height: 32px;
        height: 32px;
        width: 32px;

        .mat-icon {
          font-size: 16px;
          height: 16px;
          line-height: 16px;
          width: 16px;
          vertical-align: middle;
          color: $color-text-gray;
        }

        &:hover .mat-icon {
          color: $color-layout;
        }
      }
    }
  }

  .mp-entity-item.item--removed {
    opacity: 0.5;

    .mat-icon {
      color: $item-remove-background;
    }

    .entity-item__label {
      .entity-item__label-text,
      .entity-item__label-meta {
        color: $item-remove-background;
        text-decoration: line-through;
      }
    }
  }

  .mp-entity-item.item--hidden {
    display: none;
    visibility: hidden;
  }
}
