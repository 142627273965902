@import 'colors';

@mixin shopping-cart-panel($theme) {
  $accent: map-get($theme, accent);
  $layout: map-get($theme, layout);

  $color-success-green: mat-color($mp-state, green);
  $color-accent: mat-color($accent);
  $color-layout: mat-color($layout);
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 300);
  $color-red: mat-color($mp-state, red);

  $table-row-accent: $color-layout-10;
  $table-border-color: mat-color($layout, 50);

  .mpms-shopping-cart-panel {
    mat-expansion-panel.cart-panel {
      line-height: 21px;
      height: auto;
      margin-bottom: $default-thickness;

      > mat-expansion-panel-header {
        height: auto;
        padding: 0;

        span.mat-content {
          flex-direction: column;
          height: 100%;
          margin: 0;
        }
      }

      .mat-expansion-panel-body {
        padding: 0;
      }
    }

    .cart-panel-header__confirm-cart {
      background-color: $table-row-accent;
    }

    .cart-panel-header__confirm-cart-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5 * $default-thickness $default-thickness;
      border-top: 1px $table-border-color solid;

      button.mat-button.cart-panel-header__confirm-cart-expand-button {
        color: $color-layout;
      }

      .cart-panel-header__confirm-cart-submit-action {
        display: flex;
        flex: 0 1 320px;
        text-align: center;
        align-items: center;
        padding-right: 4 * $default-thickness;
        box-sizing: border-box;

        button.button--success {
          // TODO: implement a "success" option for color property of mat-button
          width: 100%;
          background-color: white;
          border: 1px solid $color-success-green;
          color: $color-success-green;
          box-shadow: none;

          &:disabled {
            opacity: 0.5;
          }
        }

        .deleted-position-warning {
          color: $color-red;
          padding: 0 0.5 * $default-thickness;
        }
      }
    }

    .cart-identification {
      mat-icon {
        display: inline-block;
        margin: 0 4px 0 0;
        color: $color-layout;
        line-height: 24px;
        vertical-align: top;
      }

      &__cart-name {
        display: inline-block;
        margin: 0 0.5 * $default-thickness 0 0;
        color: $color-layout;
        line-height: 24px;
        vertical-align: top;
      }

      &__cost-center-info {
        display: inline-block;
        color: $color-light-gray;
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        vertical-align: top;
        margin-top: 4px;
      }
    }

    .cart-details-column__address-info {
      font-size: 14px;
      line-height: 20px;
      color: $color-light-gray;
    }

    .cart-panel-header__position-val {
      color: $color-gray;
      display: block;
      font-size: 16px;
      line-height: 20px;
    }

    .cart-panel-header__position-label {
      color: $color-light-gray;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    }

    .cart-panel-header__toggle-cart-position-icon {
      color: $color-light-gray;
    }
  }
}

:host {
  .cart-panel-header {
    padding: 32px;
    display: flex;
    align-items: center;

    &__cart-details-column {
      flex: 1 1 auto;
      min-width: 0;
    }

    &__cart-positions-column {
      flex: 0 1 15%;
      min-width: 0;
      text-align: center;
    }

    &__cart-total-price-column {
      flex: 0 1 20%;
      min-width: 0;
    }

    &__toggle-cart-positions-column {
      flex: 0 1 50px;
      min-width: 0;
      text-align: center;
    }
  }
}
