@import 'variables';

@mixin last-viewed-articles($theme) {
  $color-gray: mat-color($mp-gray, 300);

  .mpms-last-viewed-articles {
    .last-viewed-articles__header-title {
      color: $color-gray;
      font-weight: bold;
    }
  }
}

:host {
  display: block;

  .last-viewed-articles {
    &__header {
      display: flex;
      gap: 0.5 * $default-thickness;
      align-items: center;
      margin: 0 0 $default-thickness 0;

      &-title {
        display: flex;
        align-items: center;
        gap: 0.5 * $default-thickness;
        margin: 0;
      }
    }

    &__carousel {
      &-article-preview {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    }
  }
}
