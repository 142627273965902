@import 'variables';

@mixin organisation-item($theme) {
  $layout: map-get($theme, layout);
  $color-primary: mat-color($layout, 500);

  .mp-organisation-item {
    &-tooltip.mat-tooltip {
      max-width: 300px;
      white-space: pre-line;
    }

    .organisation-item {
      &__content {
        &-name {
          font-weight: bold;
          color: $color-primary;
        }

        &-identifier,
        &-roles {
          font-size: 14px;
        }
      }

      &__tooltip-icon,
      &__navigate-button {
        color: $color-primary;
      }
    }
  }
}

:host {
  width: 100%;
  height: 100%;
  min-height: 72px;
  padding: 4px 0;

  .organisation-item {
    display: flex;
    align-items: center;
    height: 100%;

    &__avatar {
      flex: 0 0 48px;
      min-width: 0;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 $default-thickness;
      overflow: hidden;

      * {
        margin: 0;
        padding: 0;
      }
    }

    &__navigate-button {
      flex: 0 0 40px;
      min-width: 0;
    }
  }
}
