@mixin news-item-modification-info($theme) {
  $color-gray: mat-color($mp-gray, 500);

  .mp-news-item-modification-info {
    .modification-info {
      color: $color-gray;
      font-size: 14px;

      &__user-name {
        font-weight: bold;
        white-space: nowrap;
      }

      &__date {
        font-weight: normal;
      }
    }
  }
}

:host {
  display: block;
  width: 100%;

  .modification-info {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
