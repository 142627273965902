@import 'colors';

@mixin navigation-tabs-menu-global {
  $color-active: $color-layout-500;

  .mp-navigation-tabs-menu {
    .navigation-tabs-menu {
      .mat-tab-label-active {
        color: $color-active;
        opacity: 1;
      }

      .mat-ink-bar {
        background-color: $color-active;
      }
    }
  }
}
