::ng-deep .mat-progress-spinner circle, .mat-spinner circle {
  stroke: #E98C4A !important;
  stroke-width: 15% !important;
}

@mixin freigabe-queue($theme) {

  $color-orange: #E98C4A;
  $gray: mat-color($mp-gray, 600);

  .mp-freigabe-queue {
    position: relative;
    display: block;

    padding: $default-thickness $default-thickness $default-thickness*.5;
    margin: 0;

    top: 0;
    left: 0;
    right: 0;

    border-radius: 0 0 4px 4px;

    background-color: #FAFAFA;
    box-shadow: $card-box-shadow;

    @include breakpoint(lg) {
      position: absolute;
      margin-left: $default-thickness*1.5;
      margin-right: $default-thickness*1.5;
      left: 364px;
      right: 22px;
    }

    z-index: 9;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;

      .header-spinner {
        margin-right: $default-thickness;
      }

      .header-title {

        display: flex;
        flex-direction: row;

        flex: 1;

        .header-title-count {
          flex: 0;
          padding-right: .5*$default-thickness;
          font-weight: bold;
          color: $color-orange;
        }

        .header-title-label {
          flex: 1;
          color: $gray;
        }

        .header-expander {
          button {
            color: $gray !important;
          }
        }
      }
    }

    .queued-katalog {
      display: flex !important;
      flex-direction: row;
      align-items: center;

      border-left: 4px solid $color-orange;

      margin-bottom: 8px;

      &:first-child {
        margin-top: .5*$default-thickness;
      }

      &:last-child {
        margin-bottom: $default-thickness;
      }

      .queued-katalog-icon {
        color: #E1E1E1;
        margin-right: 12px;
        height: 1.5*$default-thickness;

        mat-spinner {
          width: 20px;
          height: 20px;
        }
      }

      .queued-katalog-lieferant {
        font-weight: bold;
        color: $gray;
      }

      .queued-katalog-name {
        font-weight: bold;
        color: $gray;
        font-size: 14px;

        &::before {
          display: inline-block;
          content: '|';
          color: #E1E1E1;
          padding: 0 .5*$default-thickness;
        }
      }

      .queued-katalog-state {
        flex: 1;
        color: $gray;
        text-align: end;
        font-size: 14px;
        min-width: 20%;
        margin-left: $default-thickness;

        .queued-katalog-state-label {}

        .queued-katalog-state-timing {
          font-weight: bold;
          margin-left: 6px;
        }
      }
    }

    .body {
      max-height: 0;
      overflow-y: hidden;
      transition: max-height 280ms cubic-bezier(0.4, 0, 0.2, 1);

      &.expanded {
        max-height: 65vh;
      }

    }
  }

}
