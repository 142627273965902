@import 'colors';

:host {
  display: inline-block;
  border-radius: 12px;
  line-height: 20px;
  padding: 0 1px;
  margin: 0 8px;

  mat-icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
    vertical-align: middle;
    margin: 0;
  }

  span {
    line-height: 20px;
    font-size: 14px;
    font-weight: bold;
    vertical-align: middle;
    padding-top: 2px;
    display: inline-block;
    margin: 0 10px 0 2px;
  }
}

@mixin blocked-supplier-tag($theme) {
  .mp-blocked-supplier-tag {
    background-color: $color-state-red-200;
    color: $color-state-red-600;
  }
}
