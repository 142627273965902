@import 'colors';
@import 'mixins';
@import 'variables';

// Base styles for facets
@mixin facet-common {
  $font-title: 700 #{12px}/#{20px} 'Roboto';
  $font-label: 700 #{13px}/#{20px} 'Roboto';
  $font-count: 700 #{12px}/#{20px} 'Roboto';

  $light-gray: #989898;
  $gray: #757575;
  $dark-gray: #454545;

  $color-layout: $color-layout-600;
  $accent-color: $color-accent-500;

  $bg-color: #f6f6f6;
  $bg-color-focus: #f8f1e0;

  $border-color: #e8e8e8;

  .facet--panel {
    display: block;
    user-select: none;
    margin: 0;
    border-bottom: 1px $border-color solid;
    transition: margin 0.2s ease-in-out;

    &:not(.is--expanded):hover .facet--head {
      background-color: $bg-color-focus;
    }

    &.is--expanded {
      margin: $default-thickness * 0.5 0 $default-thickness 0;
      border: none;

      .facet--head {
        background-color: transparent;

        .facet--title,
        .facet--icon {
          color: $color-layout;
        }
      }

      .facet--list {
        max-height: 270px;
        transition: max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        overflow-y: auto;
      }

      .facet--searchfield {
        margin-bottom: 2px;
      }

      .no-buckets-message {
        color: $color-gray-300;
        font-size: 13px;
        font-weight: bold;

        text-align: center;
        margin-bottom: 0;
      }
    }
  }

  .facet--head {
    border-radius: $default-thickness * 0.25;
    display: flex;
    justify-content: stretch;
    align-items: center;
    cursor: pointer;

    background: $bg-color;
  }

  .facet--title {
    font: $font-title;
    color: $light-gray;
    letter-spacing: 0.0075em;
    margin: 0;
    padding: $default-thickness * 0.5 $default-thickness;
    flex: 1 1 auto;
  }

  .facet--icon {
    height: 20px;
    margin-left: $default-thickness;
    color: $light-gray;

    .mat-icon {
      @include icon-size(16px);
      color: inherit;
    }

    ~ .facet--title {
      padding-left: 0.75 * $default-thickness;
    }
  }

  .facet--selection-info {
    font: $font-title;
    color: $accent-color;
    letter-spacing: 0.0075em;
    margin: 0;
  }

  .facet--btn {
    color: $light-gray;
    margin-left: 5px;
    width: 24px;
    height: 24px;
    line-height: 24px;

    &:hover {
      color: $dark-gray;
    }

    mat-icon {
      font-size: 16px;
    }
  }

  .facet--list {
    padding: 0;
    margin: 0;

    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }

  .facet--bucket {
    background: $bg-color;
    border-radius: $default-thickness * 0.25;
    list-style: none;
    margin: 0 0 2px 0;

    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: $gray;

    &:hover {
      color: $dark-gray;
      background: $bg-color-focus;
    }

    %checkbox-label {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      gap: 0.5 * $default-thickness;
      min-width: 0;
    }

    .facet--bucket-content {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    .facet--bucket-label {
      flex: 1 1 auto;
    }

    &.mat-checkbox,
    &.mat-radio-button {
      > label {
        width: 100%;
        padding: $default-thickness * 0.5 $default-thickness;
        box-sizing: border-box;
      }
    }

    &.mat-checkbox {
      > label {
        .mat-checkbox-label {
          @extend %checkbox-label;
        }
      }
    }

    &.mat-radio-button {
      > label {
        .mat-radio-label-content {
          @extend %checkbox-label;
        }
      }
    }
  }

  .facet--bucket-content {
    .mat-icon {
      @include icon-size(20px);
      flex: none;

      &:first-child {
        margin: 0 0.5 * $default-thickness 0 0;
      }
    }
  }

  .facet--bucket-content,
  .facet--bucket-label {
    font: $font-label;
    cursor: inherit;
  }

  .facet--bucket-count {
    font: $font-count;
    color: $light-gray;
  }

  .facet--more {
    font-size: 12px;
    color: $gray;
    line-height: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 12px;

    &:hover {
      color: $dark-gray;
    }
  }
}
