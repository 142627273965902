@mixin article-item-unit-theme($theme) {
  $color-gray: map-get($mp-gray, 500);

  .mpdm-article-item-unit {
    color: $color-gray;
    font-size: 14px;
    line-height: 16px;

    .article-item-unit__unit-name,
    .item-base-unit__base-unit-quantity {
      font-weight: bold;
    }

    .article-item-unit__unit-long-name,
    .item-unit-price__base-unit-name,
    .item-unit-price__base-unit-long-name {
      font-weight: normal;
    }
  }
}
