@import 'colors';
@import 'variables';

@mixin overview-item-global {
  .mp-overview-item {
    .link {
      .content > h2 {
        margin-bottom: 8px;

        &:only-child {
          margin: 0;
        }
      }
    }
  }
}
