@mixin mp-view($theme) {
  $primary: map-get($theme, primary);
  $layout: map-get($theme, layout);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $title-bar-background: mat-color($background, dialog);
  $context-bar-background: mat-color($background, card);

  $tab-item-foreground: mat-color($layout, 500);
  $tab-item-foreground-active: mat-color($primary, 500);
  $tab-item-foreground-disabled: mat-color($foreground, disabled-button);

  .mp-view-action-fab {
    position: relative;
    top: 24px;
    right: $default-thickness * 2;

    &--custom {
      top: 40px;
    }
  }

  .mp-view {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: 100%;

    .title-bar {
      z-index: 2;
      display: flex;
      align-items: center;
      height: $default-toolbar-height;
      padding-left: $default-thickness;
      background: $title-bar-background;
      @include mat-typography-level-to-styles($mp-typography, title);

      .title {
        flex: 1;
      }
    }

    .detail-page {
      display: flex;
      flex-direction: column;
      position: relative;
      flex: 1;
      padding: $default-thickness;

      .detail-page-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: $default-thickness;

        .page-column {
          .mat-form-field {
            width: 50%;
            min-width: 400px;
            margin-right: $default-thickness * 2;
            margin-bottom: $default-thickness;

            .mat-hint {
              flex: 1;
              text-align: right;
            }

            .mat-form-field-hint-spacer {
              flex: 0;
            }
          }

          .input-group {
            display: block;

            & > .mat-form-field:first-child {
              width: unset;
              min-width: unset;
            }
          }
        }

        .mat-tab-group {
          margin-top: $default-thickness * 2;

          .mat-tab-label {
            opacity: unset;
            color: $tab-item-foreground;
            text-transform: uppercase;
          }

          .mat-tab-disabled {
            opacity: unset;
            color: $tab-item-foreground-disabled;
          }

          .mat-tab-label-active {
            opacity: unset;
            color: $tab-item-foreground-active;
          }

          .mat-tab-body-content {
            height: unset;
            overflow: hidden;
            padding-top: $default-thickness;
          }
        }
      }
    }

    .detail-page-context-bar {
      display: flex;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      bottom: 0;
      background: $context-bar-background;
      box-shadow: $default-box-shadow;
    }

    &:not(.mp-view--custom) {
      overflow: hidden;

      .detail-page {
        overflow: hidden;

        .detail-page-content {
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}
