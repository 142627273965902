@import 'colors';

@import '../facet-common';

@mixin tree-facet {
  $light-gray: #989898;
  $gray: #757575;
  $dark-gray: #454545;

  $font-label: 700 #{13px}/#{20px} 'Roboto';
  $font-count: 700 #{12px}/#{20px} 'Roboto';

  .mp-tree-facet {
    @include facet-common;

    .facet--type-select {
      display: inline-block;

      --mp-select-value-color: #{$color-layout-600};
      --mp-select-arrow-color: #{$color-layout-600};
    }

    .facet--panel.is--expanded {
      .facet--list {
        max-height: none;
      }

      .facet--actions {
        flex: 1 0 auto;
        text-align: right;
      }
    }

    .facet--list {
      padding-left: $default-thickness;
      padding-right: $default-thickness;

      .facet--no-buckets {
        text-align: center;
        font: $font-label;
        font-weight: 400;
        color: $light-gray;
        margin-top: $default-thickness;
      }
    }

    .facet--bucket {
      padding: 0;
      display: block;

      &,
      &:hover {
        background-color: transparent;
        color: $gray;
      }

      &-selected {
        > .facet--bucket-head {
          .facet--bucket-expand-icon,
          .facet--bucket-label,
          .facet--bucket-count {
            color: $color-accent-500;
          }
        }
      }
    }

    .facet--bucket-head {
      display: flex;
      flex: 1 1 auto;
      min-width: 0;
      align-items: center;

      .facet--bucket-label {
        flex: 1 1 auto;
      }
    }

    .facet--bucket-expand-icon {
      flex: 0 0 auto;
      height: 24px;
      width: 24px;
      line-height: 24px;
      font-size: 16px;
      color: $light-gray;
      text-align: center;

      .mat-icon {
        width: 16px;
        height: 16px;

        margin: 0;
        color: inherit;
        font-size: inherit;
      }

      .facet--bucket-expand-icon-svg {
        height: 8px;
        width: 8px;

        margin: 0;

        position: relative;
        top: -3px;

        path {
          fill: currentColor;
        }
      }

      &:hover {
        color: $dark-gray;
      }
    }

    .facet--bucket-label,
    .facet--bucket-count {
      line-height: 24px;
    }

    .facet--bucket-label:hover {
      color: $dark-gray;
    }

    .facet--bucket-children {
      margin-left: $default-thickness;
    }
  }

  // Default search results

  .facet--search-field-option-result {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .facet--search-field-option-result-label,
  .facet--search-field-option-result-count {
    color: $gray;
  }

  .facet--search-field-option-result-label {
    font: $font-label;

    .facet--search-field-option-result-code {
      font-family: monospace;
      line-height: 16px;
      letter-spacing: 0.025em;
    }
  }

  .facet--search-field-option-result-count {
    font: $font-count;
  }
}
