@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin _unselected-cell {
  &:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    @content;
  }
}

// Styles for a highlighted calendar cell (e.g. hovered or focused).
@mixin _highlighted-cell($color) {
  & > .mat-calendar-body-cell-content {
    @include _unselected-cell {
      background-color: color-utils.add-opacity-to-color-variable($color, 0.3);
    }
  }
}

@mixin _color($default-color) {
  .cdk-keyboard-focused .mat-calendar-body-active,
  .cdk-program-focused .mat-calendar-body-active {
    @include _highlighted-cell($default-color);
  }

  @media (hover: hover) {
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover {
      @include _highlighted-cell($default-color);
    }
  }
}

// NOTE: In case mat-date-range-picker is used some additional overrides may be needed.
@mixin datepicker-overrides {
  @include _color($color-primary-500);

  .mat-datepicker-content {
    &.mat-accent {
      @include _color($color-accent-500);
    }

    &.mat-warn {
      @include _color($color-warn-500);
    }
  }
}
