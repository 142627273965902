@import 'colors';

@mixin thin-scrollbar-default-styles {
  // Uses webkit to adjust the appearance of scrollbar. It does not support
  // all of the major browsers but that is expected behavior and solution.
  @supports selector(::-webkit-scrollbar) {
    $scrollbar-size: 6px;

    ::-webkit-scrollbar {
      width: $scrollbar-size;
      height: $scrollbar-size;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $color-gray-200;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:vertical:hover,
    ::-webkit-scrollbar-thumb:horizontal:hover {
      background-color: $color-gray-300;
    }
  }
}
