:host {
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px 0 0 4px;
}

@mixin entity-item-status($theme) {
  $state-green: mat-color($mp-state, green);
  $state-yellow: mat-color($mp-state, yellow);
  $state-orange: mat-color($mp-state, orange);
  $state-red: mat-color($mp-state, red);
  $state-grey: mat-color($mp-state, grey);

  mp-entity-item-status {
    background-color: $state-grey;

    &.green {
      background-color: $state-green;
    }

    &.yellow {
      background-color: $state-yellow;
    }

    &.orange {
      background-color: $state-orange;
    }

    &.red {
      background-color: $state-red;
    }
  }
}
