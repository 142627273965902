@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin _vendor-input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin input-overrides {
  .mat-input-element {
    @include _vendor-input-placeholder {
      color: var(--mp-input-placeholder-color, $input-placeholder-color);
    }

    &:disabled {
      @include _vendor-input-placeholder {
        color: $input-placeholder-color;
      }
    }
  }
}
