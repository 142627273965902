@import 'colors';

@mixin mp-alert($theme) {
  $layout: map-get($mp-theme, layout);

  $warn: map-get($mp-theme, warn);
  $dark-red: mat-color($warn, 500);
  $medium-red: mat-color($warn, 200);
  $light-red: mat-color($warn, 50);

  .mp-alert {
    .headline {
      display: flex;

      & > [mpAlertTitle] {
        flex: 1;
      }
    }

    .alert-info,
    .alert-warning,
    .alert-danger {
      padding: $default-thickness;
      margin-bottom: $default-thickness;

      border: 1px solid;
      border-radius: $default-border-radius;

      mat-icon {
        margin-right: $default-thickness;
        margin-bottom: $default-thickness;
      }
    }

    .alert-info {
      border-color: mat-color($layout, 500);

      mat-icon {
        color: mat-color($layout, 500);
      }
    }

    .alert-warning {
      border-color: $color-gold-200;
      background-color: $color-gold-50;

      mat-icon {
        color: $color-gold-400;
      }
    }

    .alert-danger {
      border-color: $medium-red;
      background-color: $light-red;

      mat-icon {
        color: $dark-red;
      }
    }
  }
}
