@mixin alle-aktualisieren-button($theme) {
  $color-orange: mat-color($mp-state, orange);

  .mp-update-all-button {
    button.mat-button {
      background-color: white;
      color: $color-orange;

      .mat-button-wrapper {
        line-height: 100%;
      }
    }

    .button-content {
      display: flex;
      align-items: center;

      mat-icon {
        display: block;
        flex: 0 0 20px;
        font-size: 20px;
        width: 20px;
        height: 20px;
      }

      span {
        flex: 1;
        margin-left: $default-thickness*.5;
      }
    }

    .page-selected .content-col,
    .all-selected .dropdown-col {
      button:not([disabled]) {
        background-color: $color-orange;
        border-color: $color-orange !important;

        &,
        mat-icon {
          color: white;
        }
      }
    }

    .mp-dropdown-button {

      .button-content-row {

        .content-col {
          button {
            border-radius: 4px 0 0 4px!important;
            border-right: 0!important;
          }
        }
        .dropdown-col {
          border-left: none!important;

          button {
            border-radius: 0 4px 4px 0!important;
            border: 1px currentColor solid;

            mat-icon {
              vertical-align: top;
              line-height: 34px;
              height: 34px;
            }

            &:not([disabled]) {
              border: 1px $color-orange solid;
            }
          }
        }
      }
    }

  }

}
