@mixin export-button($theme) {
  $layout: map-get($theme, layout);

  $primary-color: mat-color($layout, 500);

  .mp-export-button {
    .export-button {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
      color: $primary-color;

      &:disabled {
        opacity: 0.5;
      }
    }

    .export-in-progress-button {
      padding: 4px 20px;
      width: 100px;

      svg circle {
        stroke: #fff !important;
      }
    }
  }
}

:host {
  .export-in-progress-button {
    display: flex;
    justify-content: center;
  }
}
