@import 'variables';

@mixin count-up-stat($theme) {
  $color-gray: mat-color($mp-gray, 400);
  $color-green: mat-color($mp-state, green);
  $color-red: mat-color($mp-state, red);
  $mp-accent: map-get($theme, accent);
  $color-accent: mat-color($mp-accent, 500);

  $stat-value-font-size: 48px;

  .mpcm-count-up-stat {
    .stat {
      &--clickable {
        cursor: pointer;

        &:hover .stat__label-text{
          color: $color-accent;
        }
      }

      &__value {
        font-weight: bold;
        font-size: 32px;
        line-height: $stat-value-font-size;
        white-space: nowrap;

        @include breakpoint(lg) {
          font-size: $stat-value-font-size;
        }
      }

      &__label {
        font-size: 14px;
        line-height: 16px;

        &-text {
          color: $color-gray;
          font-weight: bold;
        }

        &-icon {
          margin-left: $default-thickness * 0.5;
          width: 20px;
          height: 20px;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }

    &--success {
      .stat__value {
        color: $color-green;
      }
    }

    &--danger {
      .stat__value {
        color: $color-red;
      }
    }
  }
}

:host {
  display: block;

  .stat {
    display: inline-flex;
    flex-direction: column;

    &__label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: $default-thickness * 0.5;
    }
  }
}
