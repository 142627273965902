@mixin mp-basic-flyout($theme) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $title-bar-background: mat-color($background, dialog);
  $flyout-background: mat-color($background, background);
  $context-bar-background: mat-color($background, card);

  .mp-flyout {
    position: relative;
    z-index: 2;

    width: 464px;
    height: 100%;

    padding-top: $default-toolbar-height;

    .mp-basic-flyout {
      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      background-color: $flyout-background;
      box-shadow: $default-box-shadow;

      .title-bar {
        display: flex;
        flex: 0 0 $default-toolbar-height;
        align-items: center;

        height: $default-toolbar-height;

        padding-left: $default-thickness;

        background: $title-bar-background;
        z-index: 3;

        @include mat-typography-level-to-styles($mp-typography, title);

        .title {
          flex: 1;
        }

        .action-fab {
          position: relative;
          top: 24px;
          right: $default-thickness * 2;
        }
      }

      .content {
        position: relative;
        flex: 1;
        height: calc(100% - #{$default-toolbar-height});

        &-action-fab {
          position: absolute;

          z-index: 1;

          bottom: 1.5 * $default-thickness;
          right: 2 * $default-thickness;
        }
      }
    }
  }
}
