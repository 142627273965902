@mixin mp-selector($theme) {
  $background: map-get($theme, background);
  $title-bar-background: mat-color($background, dialog);
  $context-bar-background: mat-color($background, card);

  .mp-flyout-selector {
    display: block;
    width: 100%;
    height: 100%;

    background-color: $context-bar-background;
    box-shadow: $default-box-shadow;

    &__item-tooltip.mat-tooltip {
      max-width: 350px;
    }

    .selector-content {
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;

      .search-bar {
        flex: 1 0 auto;
        padding: $default-thickness $default-thickness 0 $default-thickness;

        mat-form-field {
          width: 100%;
        }
      }

      .context-view-content {
        flex: 0 1 100%;
        overflow-y: auto;
        padding: $default-thickness;

        &__virtual-scroll {
          height: 100%;
        }
      }
    }
  }
}
