@import 'colors';

@mixin article-tabs-wrapper-global {
  .mpcm-article-tabs-wrapper {
    .article-tabs {
      .mat-tab-nav-bar,
      .mat-tab-header {
        border-bottom-color: $color-gray-100;
      }

      .mat-tab-label {
        opacity: 1;
        color: $color-gray-500;

        &.mat-tab-label-active {
          color: $color-accent-500;
        }

        &.mat-tab-disabled {
          color: $color-gray-100;
        }
      }

      .mat-ink-bar {
        background-color: $color-accent-500;
      }
    }
  }
}
