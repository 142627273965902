@import 'colors';

@mixin image-gallery-overlay-global {
  .image-gallery-overlay {
    width: 90%;
    height: 90%;

    .mat-dialog-container {
      position: relative;
      background-color: $color-gray-25;
    }
  }
}
