$color-gold-10: #fdfaf5;
$color-gold-25: #fbf7ec;
$color-gold-50: #f8f1e0;
$color-gold-100: #efddb3;
$color-gold-200: #e4c680;
$color-gold-250: #ffd87c; // lighten($color-gold-500, 35%)
$color-gold-300: #d9af4d;
$color-gold-400: #d09d26;
$color-gold-450: #fbb000; // lighten($color-gold-500, 10%)
$color-gold-500: #c88c00;
$color-gold-600: #c28400;
$color-gold-700: #bb7900;
$color-gold-800: #b46f00;
$color-gold-900: #a75c00;

$color-gray-10: #fdfdfd;
$color-gray-25: #f6f6f6;
$color-gray-50: #f0f0f0;
$color-gray-75: #e6e6e6;
$color-gray-100: #d9d9d9;
$color-gray-150: #cdcdcd;
$color-gray-200: #c0c0c0;
$color-gray-300: #a6a6a6;
$color-gray-400: #939393;
$color-gray-500: #808080;
$color-gray-600: #787878;
$color-gray-700: #6d6d6d;
$color-gray-800: #636363;
$color-gray-900: #505050;

$color-light-green-50: #e0f6ef;
$color-light-green-100: #b3e8d8;
$color-light-green-200: #80d9be;
$color-light-green-300: #4dc9a3;
$color-light-green-400: #26be90;
$color-light-green-500: #00b27c;
$color-light-green-600: #00ab74;
$color-light-green-700: #00a269;
$color-light-green-800: #00995f;
$color-light-green-900: #008a4c;

$color-state-green-100: #f3faf2;
$color-state-green-200: #e3f3df;
$color-state-green-500: #82c970;

$color-state-yellow-100: #fff2c6;
$color-state-yellow-300: #e9c64a;
$color-state-yellow-500: #dc9d3e;
$color-state-yellow-600: #c88c00;

$color-state-orange-100: #fbebe0;
$color-state-orange-200: #fae3d2;
$color-state-orange-500: #e98c4a;

$color-state-red-50: #fefafb;
$color-state-red-100: #f9e6e9;
$color-state-red-200: #f4d1d7;
$color-state-red-300: #efbdc6;
$color-state-red-500: #d6566d;
$color-state-red-600: #c8314c;

$color-state-gray-500: #e1e1e1;
