@import 'colors';
@import 'mixins';
@import 'variables';

@mixin article-details-offers-theme($theme) {
  $layout: map-get($theme, layout);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);

  $color-layout: mat-color($layout, 500);
  $color-gray: mat-color($mp-gray, 600);
  $color-lightgray: #f6f6f6;

  $border-color: #dfdfdf;
  $option-background: $color-layout-10;

  $hover-background: mat-color($background, hover);

  $color-accent: mat-color($accent, 500);

  $font-title: 700 #{16px}/#{20px} 'Roboto';
  $font-subtitle: 600 #{14px}/#{20px} 'Roboto';
  $font-info: 300 #{14px}/#{20px} 'Roboto';

  .mpcm-article-details-offers {
    .offers {
      .mat-expansion-panel-body {
        overflow: hidden;
      }

      &__header {
        .mat-expansion-indicator::after {
          box-sizing: border-box;
          border-style: solid;
          border-width: 10px 5px 0 5px;
          border-color: $color-gray transparent transparent transparent;
          content: '';
          padding: 0;
          transform: none;
          width: 10px;
          height: 10px;
          font-size: 0;
          box-sizing: border-box;
        }
      }

      &__title {
        color: $color-layout !important;
        font: $font-subtitle;
        text-transform: uppercase;
      }

      &__count {
        text-align: center;
        height: 18px;
        margin-right: 8px;

        padding: 0 8px;

        border-radius: 2px;

        font: $font-subtitle;
        line-height: 18px;

        color: $color-layout;
        background-color: $color-lightgray;
      }
    }

    .offer-card {
      background-color: $color-lightgray;
      border-radius: 4px;
      border: 2px solid $color-lightgray;

      &--selected {
        border-color: $color-primary-100;
      }
    }

    .offer-row {
      &:hover {
        background-color: $hover-background;
      }

      &__radio-button {
        .mat-radio-outer-circle {
          background-color: white !important;
        }
      }

      &__title {
        font: $font-title;
        color: $color-gray;
      }

      &__infos,
      &__supplier-selector__field {
        font: $font-info;
        color: $color-gray;

        &__article-number {
          color: $color-gray;
        }
      }

      &__type-icon {
        font-size: 20px;
        color: $color-gray;
      }

      &.selected {
        background-color: #fff;

        .offer-row__title,
        .offer-row__infos {
          color: $color-layout;
        }
      }
    }
  }

  .mpcm-article-details-offers-supplier-option {
    display: flex;
    align-items: center;
    gap: 4px;

    font-size: 14px;

    .mpcm-article-details-offers-supplier-option__icon {
      flex: 0 0 18px;
      @include icon-size(18px);
    }
  }
}
