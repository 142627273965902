@mixin datasource-upload-widget($theme) {
  .mpcm-datasource-upload-widget {
    display: block;

    .mat-stroked-button.upload-button {
      background: white;
      border-color: white;
      box-shadow: 0px 1px 2px 0px #00000015;
      margin: 8px 0;
    }

    mat-list > mpcm-datasource-upload-list-item:nth-child(even) > mat-list-item {
      background-color: #fbfbfb;
    }

    .footer {
      background-color: #f3f8f9;
      color: #989898;
      width: 100%;
    }

    .result__list {
      margin: 12px 0 0 0;
    }
  }
}
