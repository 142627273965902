@import 'colors';
@import 'mixins';

@mixin content-section {
  $color-light-gray: #898989;
  $color-gray: #dedede;
  $color-dark-gray: $color-gray-600;

  $color-match-highlight-user: orange;

  $font-title: 300 #{20px}/#{20px} 'Roboto';
  $font-meta: 300 #{13px}/#{20px} 'Roboto';
  $font-value: 700 #{14px}/#{20px} 'Roboto';
  $font-mono:
    700 #{14px}/#{20px} 'Courier New',
    Courier,
    monospace;

  $transition-compact-mode: 0.15s ease-in-out;

  .artikel-section-tab {
    width: 30%;

    margin-inline: auto;
    padding-block: $default-thickness;

    font: $font-title;
    background-color: #fff;
    color: $color-dark-gray;
    letter-spacing: 0.025em;
    text-align: center;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    transition: padding-block $transition-compact-mode;

    &.advanced-view {
      padding-block-end: 0.25 * $default-thickness;
    }
  }

  .artikel-section-tab + .artikel-section {
    border-top: 1px solid $color-gray;
    border-bottom: 3px solid $color-gray;
  }

  .artikel-section {
    position: relative;

    background-color: #fff;

    padding-block: 0.75 * $default-thickness;
    transition: padding-block $transition-compact-mode;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-right: 2px dashed $color-gray;
      box-sizing: border-box;
      background: rgb(248, 248, 248);
      background: linear-gradient(-90deg, rgba(248, 248, 248, 1) 0%, rgba(248, 248, 248, 0) 100%);
    }

    .divider {
      position: relative;
      padding-block: 0.75 * $default-thickness;
      transition: padding-block $transition-compact-mode;

      box-sizing: content-box;
      height: 1px;

      &::after {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        height: 1px;
        background-color: $color-gray;
        box-sizing: border-box;
      }

      &:last-of-type {
        display: none;
      }
    }

    &.advanced-view {
      padding-block: 0;
      .divider {
        padding-block: 0;
      }
    }

    .artikel-section-row {
      display: flex;

      .artikel-section-col-left,
      .artikel-section-col-right {
        position: relative;
        display: flex;
        flex: 0 0 50%;
        min-width: 0;
        box-sizing: border-box;
        justify-content: space-between;

        padding-block: 0.25 * $default-thickness;
        padding-inline: $default-thickness * 2;
      }

      .artikel-section-col-left {
        // Matcher-Linie
        &::after {
          top: 0.125 * $default-thickness;
          bottom: 0.125 * $default-thickness;
        }
      }

      &.matched {
        .artikel-section-col-left::after {
          content: '';

          position: absolute;
          right: -1px;

          border-right: 4px solid $color-match-highlight-user;
          border-radius: 2px;
        }
      }

      &.dense {
        + .artikel-section-row.dense {
          .artikel-section-col-left,
          .artikel-section-col-right {
            margin-block-start: -0.5 * $default-thickness;
          }
        }
      }

      .artikel-section-label {
        flex: 0 1 auto;
        font: $font-meta;
        color: $color-light-gray;
        min-width: 0;
      }

      .artikel-section-value {
        flex: 1 1 auto;
        font: $font-value;
        color: $color-dark-gray;

        &.font-mono {
          font: $font-mono;
        }

        &-tooltip {
          @include icon-size(16px);
          vertical-align: text-top;

          &:not(:first-child) {
            margin-left: 4px;
          }

          &:not(:last-child) {
            margin-right: 4px;
          }
        }
      }

      &.raw-field {
        .artikel-section-label,
        .artikel-section-value {
          font-size: 10px;
          line-height: 1.2;
        }

        .artikel-section-value {
          font-family: Monospace;
        }
      }

      .artikel-section-col-left .artikel-section-value,
      .artikel-section-col-right .artikel-section-label {
        text-align: end;
      }
    }
  }
}
