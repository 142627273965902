@import 'colors';
@import 'variables';

@mixin table-default-styles {
  $table-header-row-min-height: 40px;

  $table-header-row-border: 2px $table-border-color solid;
  $table-row-border: 1px $table-border-color solid;

  $table-header-cell-color: $color-gray-400;
  $table-cell-color: $color-gray-600;

  $table-even-row-bg-color: $color-layout-10;

  // There are also some raw tables (without mat-table directive)
  table,
  table.mat-table {
    width: 100%;
  }

  table.mat-table {
    table-layout: auto;
  }

  // Either mat-table or normal table with mat-table directive
  .mat-table {
    &.no-bottom-border {
      mat-row.mat-row {
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  mat-row.mat-row,
  mat-header-row.mat-header-row,
  mat-footer-row.mat-footer-row,
  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    border-bottom-color: $table-border-color;
  }

  div.mat-no-data-row {
    color: $table-cell-color;
    background-color: $table-even-row-bg-color;
    border-bottom: $table-row-border;
  }

  div.mat-no-data-row {
    text-align: center;
    padding-block: $default-thickness;
  }

  mat-header-row.mat-header-row,
  th.mat-header-cell {
    border-bottom-width: 2px;
  }

  mat-header-row.mat-header-row {
    min-height: $table-header-row-min-height;
  }

  mat-header-cell.mat-header-cell {
    color: $table-header-cell-color;
  }

  mat-header-cell.mat-header-cell,
  mat-cell.mat-cell {
    padding: $table-cell-padding;
    box-sizing: border-box;

    &:first-of-type {
      padding-left: var(--table-first-cell-left-padding, 2 * $table-cell-padding);
    }

    &:last-of-type {
      padding-right: var(--table-last-cell-right-padding, 2 * $table-cell-padding);
    }
  }

  tr.mat-row,
  mat-row.mat-row {
    &:nth-of-type(even) {
      background-color: $table-even-row-bg-color;
    }

    &:hover {
      background: $color-background-hover;
      transition: $default-transition;
    }
  }

  mat-row.mat-row {
    min-height: $table-row-min-height;
  }

  mat-cell.mat-cell {
    color: $table-cell-color;
  }
}
