@import 'colors';

@mixin select-global {
  .mp-select {
    $select-value-default-color: $color-foreground-text;
    $select-arrow-default-color: $dropdown-icon-color;

    .mat-select-trigger {
      height: 100%;
    }

    .mat-select-value {
      color: var(--mp-select-value-color, #{$select-value-default-color});
    }

    .mat-select-arrow {
      color: var(--mp-select-arrow-color, #{$select-arrow-default-color});
    }
  }
}
