@import 'colors';
@import 'mixins';

@mixin mp-search-field($theme) {
  :root {
    --search-field-text-color: #{$color-foreground-text};
    --search-field-label-color: #{$color-gray-600};
    --search-field-background-color: white;
    --search-field-border-color: #{$color-gray-25};
    --search-field-box-shadow: 0 0 1px 1px rgb(0 0 0 / 5%), 0 1px 1px 0px rgb(0 0 0 / 10%);
  }

  .mp-search-field {
    display: block;
    position: relative;
    width: 100%;
    color: var(--search-field-text-color);

    // Default styling (flat)
    .wrapper {
      display: flex;
      flex-direction: row;

      width: 100%;
      height: 100%;

      .search-field-col {
        flex: 1 0 100%;

        & > mat-form-field {
          width: 100%;
          font-size: 14px;

          input::placeholder {
            color: var(--search-field-label-color);
          }

          .mat-form-field-outline {
            background-color: var(--search-field-background-color);
            overflow: hidden;
            border-radius: 3px;

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
              color: var(--search-field-border-color);
            }

            .mat-form-field-outline-start {
              border-radius: 3px 0 0 3px;
            }

            .mat-form-field-outline-gap {
              border-radius: 0;
            }

            .mat-form-field-outline-end {
              border-radius: 0 3px 3px 0;
            }
          }

          .mat-form-field-wrapper {
            padding-bottom: 0 !important;
            margin: 0;

            label {
              color: var(--search-field-label-color);
            }

            mat-icon {
              color: var(--search-field-label-color);
            }
          }

          .mat-form-field-flex > .mat-form-field-infix {
            padding: 0.2em 0 0.6em 0 !important;
          }

          input {
            margin-bottom: 0.2em;
            padding-left: 4px;
          }

          .mat-form-field-label-wrapper {
            top: -1.8em;
          }

          .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
            .mat-form-field-label {
            transform: translateY(-0.4em) scale(0.75) !important;
            width: 133.33333%;
          }
        }
      }

      .search-button-col {
        flex: 0;
        margin-left: 16px;
      }
    }

    // Special styling (raised)
    &.raised {
      .mat-form-field-wrapper {
        box-shadow: var(--search-field-box-shadow);
        border-radius: 3px;
        overflow: hidden;
      }

      mat-form-field {
        &:not(.mat-focused):not(:hover) .mat-form-field-outline {
          color: transparent;
        }
      }
    }

    &--disabled {
      .wrapper {
        .search-field-col {
          & > mat-form-field {
            .mat-form-field-wrapper {
              mat-icon {
                color: lightgray;
              }

              .mat-form-field-outline {
                background-color: rgba(255, 255, 255, 0.5);
              }

              input::placeholder {
                color: lightgray;
              }
            }
          }
        }
      }

      &.raised {
        .mat-form-field-wrapper {
          box-shadow: none;
        }
      }
    }

    // Stacked with some element on the left (meaning that we dont want rounded corners on the left)
    &.raised.stacked {
      .mat-form-field-wrapper {
        border-radius: 0 3px 3px 0;
      }

      .wrapper {
        .search-field-col {
          & > mat-form-field {
            .mat-form-field-outline {
              border-radius: 0 3px 3px 0;

              .mat-form-field-outline-start {
                border-radius: 0;
              }
            }
          }
        }
      }

      .mat-form-field-infix {
        width: auto;
      }
    }
  }
}
