@import 'colors';

.mpcm-matcher-dialog-panel {
  mat-dialog-container {
    padding: 0 !important;
  }
}

@mixin matcher-dialog {
  $color-light-gray: #f5f5f5;
  $color-gray: #dedede;
  $color-dark-gray: $color-gray-600;
  $color-dark-light: #b3b3b3; // Die gleiche Farbe wie list-count-title, TODO: nicht hart kodieren

  $font-subtitle: 700 #{14px}/#{20px} 'Roboto';

  $font-mono:
    700 #{14px}/#{20px} 'Courier New',
    Courier,
    monospace;

  $header-height: 180px;

  .mpcm-matcher-dialog {
    display: block;
    overflow: hidden;

    width: 100%;
    height: 100%;

    .dialog-panel {
      position: relative;

      width: 100%;
      height: 100%;

      display: flex;

      &.match {
        @each $state, $color in (match: $color-state-green-500, matchproposal: $color-state-orange-500) {
          &--#{$state} {
            .dialog-content-header {
              .match-icon-wrapper mat-icon.match-icon-wrapper__icon {
                background-color: $color;
              }

              &__mid-container-info-match {
                color: $color;
                border-color: $color;
                background-color: rgba($color, 0.1);
              }
            }
          }
        }
      }

      .dialog-content {
        background-color: white;

        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .dialog-content-header {
          position: relative;

          box-sizing: border-box;
          height: $header-height;
        }

        .dialog-content-body {
          flex: 1;
          overflow-y: scroll;
          border-top: 1px solid $color-gray;

          position: relative;

          background-color: $color-light-gray;
        }
      }

      .dialog-sidebar {
        display: flex;
        flex-direction: column;

        flex: 0 0 400px;
        min-width: 0;

        background-color: $color-light-gray;
        border-left: 1px solid $color-gray;

        .dialog-sidebar-section-title {
          font-size: 14px;
          letter-spacing: 0.025em;
          text-transform: uppercase;
          color: $color-dark-gray;
          margin-bottom: 32px;
        }

        .dialog-sidebar-header {
          height: $header-height;
          flex: none;

          box-sizing: border-box;

          padding: $default-thickness;

          .match-view-toggle {
            float: left;
            margin-top: -$default-thickness;

            small {
              color: $color-dark-gray;
              font-weight: bold;
            }

            &.mat-checked {
              small {
                color: $color-accent-500;
              }
            }
          }
        }

        .dialog-sidebar-body {
          flex: 1;
          overflow-y: auto;

          .mpcm-artikel-match-group {
            padding-bottom: $default-thickness;
            // margin-bottom: .5*$default-thickness;

            &:last-of-type {
              border-bottom: none;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }

          .dialog-sidebar-body-results {
            padding: $default-thickness;
            border-top: 1px solid $color-gray;
          }

          .dialog-sidebar-body-matches {
            min-height: 200px;

            padding: $default-thickness;
            border-top: 1px solid $color-gray;
          }

          .mp-spinner {
            color: $color-dark-light;
          }

          .dialog-sidebar-body-match-group {
            margin-top: $default-thickness;

            &:not(:first-of-type) {
              padding-top: $default-thickness;
              border-top: 1px solid $color-gray;
            }
          }
        }

        .dialog-sidebar-footer {
          flex: 0;

          .dialog-sidebar-footer-actions {
            display: flex;
            border-top: 1px solid $color-gray;

            .dialog-action-cancel,
            .dialog-action-save {
              flex: 1 1 auto;
              border-radius: 0 !important;
              height: 48px;
            }

            .dialog-action-cancel {
              flex: 0 0 33%;
              color: $color-dark-gray;
              background-color: $color-light-gray;
            }
          }
        }
      }

      .dialog-action-settings,
      .dialog-action-close {
        position: absolute;

        top: $default-thickness * 0.5;
        right: $default-thickness * 0.5;

        color: $color-dark-gray;
      }
    }

    .font-mono {
      font: $font-mono;
    }
  }

  .dialog-action-settings-item {
    strong {
      font: $font-subtitle !important;
      color: $color-dark-gray;
    }
  }
}
