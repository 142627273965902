@mixin favoritenlisten-flyout($theme) {

  .mp-favoritenlisten-flyout {
    width: 100%;

    .wrapper {
      margin: $default-thickness;
      display: flex;
      flex-direction: column;

      mat-hint {
        text-align: right;
        width: 100%;
      }

      @include breakpoint(md){
        margin: $default-thickness*2;
      }


      .flyout-interaction {
        display: flex;
        align-self: flex-end;
        margin-top: $default-thickness*2;
        width: 100%;

        .interaction-button {
          margin: 0 $default-thickness*0.5;
          flex: 1 1 auto;
          overflow: hidden;
          border-color: inherit;

          &:first-child { margin-left: 0; }
          &:last-child { margin-right: 0; }

          .mat-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
