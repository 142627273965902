$font-mono: 'Courier New', Courier, monospace;

@mixin hitlists-quick-start-item($theme) {
  $color-gray: #757575;

  .mpms-hitlists-quick-start-item {
    color: $color-gray;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;

    .font-mono {
      font-weight: 700;
      font-family: $font-mono;
    }
  }
}

:host {
  display: flex;
  flex-direction: column;
}
