@import 'variables';
@import 'colors';

@mixin article-preview($theme) {
  $layout: map-get($theme, layout);
  $primary: map-get($theme, primary);

  $color-card-bg-hover: $color-gold-25;

  .mpms-article-preview {
    transition: background-color 150ms ease-in-out;
    border-radius: 1px;

    &:hover {
      background-color: $color-card-bg-hover;
    }

    .article-image-wrapper {
      &__article-image {
        --image-placeholder-background-color: transparent;
      }
    }

    .article-preview-header {
      &__article-name {
        cursor: pointer;
      }
    }
  }
}

:host {
  display: flex;
  flex-direction: column;
  padding: 2 * $default-thickness 1.5 * $default-thickness;

  .article-image-wrapper {
    display: flex;
    justify-content: center;
    float: left;
    width: 30%;
    margin-right: 0.5 * $default-thickness;
    flex: 0 0 30%;
    min-width: 40px;

    &__article-image {
      height: 70px;
      max-width: 100%;
      flex: 0 0 auto;
    }
  }

  .article-preview-header {
    text-align: left;
  }

  .article-supplier-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
    margin-top: $default-thickness;
    flex: 1;

    &__supplier-name,
    &__supplier-article-number {
      max-width: 100%;
    }
  }
}
