@use 'colors/utilities' as color-utils;
@import 'colors';

@mixin _form-field-control-disabled-underline($color) {
  background-image: linear-gradient(to right, $color 0%, $color 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

@mixin form-field-overrides {
  $label-color: var(--mp-form-field-label-color, #{$form-field-label-color});
  $underline-ripple-color: var(--mp-form-field-underline-ripple-color, #{$form-field-underline-ripple-color});
  $underline-color: var(--mp-form-field-underline-color, #{$form-field-underline-color});

  .mat-form-field-label,
  .mat-hint {
    color: $label-color;
  }

  .mat-form-field-ripple {
    background-color: $underline-ripple-color;
  }

  .mat-form-field-appearance-legacy,
  .mat-form-field-appearance-standard {
    .mat-form-field-underline {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled .mat-form-field-underline {
      @include _form-field-control-disabled-underline($underline-color);
    }
  }

  .mat-form-field-appearance-fill {
    .mat-form-field-underline::before {
      background-color: $underline-color;
    }
  }

  .mat-form-field-appearance-outline {
    $outline-color: color-utils.add-opacity-to-color-variable($color-foreground-divider, 0.12);
    $outline-color-hover: color-utils.add-opacity-to-color-variable($color-foreground-divider, 0.87);
    $outline-color-disabled: color-utils.add-opacity-to-color-variable($color-foreground-divider, 0.06);

    .mat-form-field-outline {
      color: $outline-color;
    }

    .mat-form-field-outline-thick {
      color: $outline-color-hover;
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline {
        color: $outline-color-disabled;
      }
    }
  }
}
