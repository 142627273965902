@import 'variables';
@import 'colors';

@mixin recent-purchase-requisitions($theme) {
  $color-gray: mat-color($mp-gray, 600);
  $color-light-gray: mat-color($mp-gray, 100);

  $no-requisitions-color: #757575;

  $color-hover: $color-gold-50;

  .mpms-recent-purchase-requisitions {
    .recent-requisitions {
      &__divider {
        border-color: $color-light-gray;
      }

      &__item {
        &:hover {
          cursor: pointer;
          background-color: $color-hover;
        }
      }
    }

    .header-title {
      color: $color-gray;

      &__text {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .no-requisitions {
      color: $no-requisitions-color;
      font-size: 14px;
    }
  }
}

:host {
  display: block;

  .recent-requisitions {
    padding: 0;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__header,
    &__item {
      padding: $default-thickness;
    }
  }

  .header-title {
    display: flex;
    align-items: center;

    &__text {
      margin-left: 0.5 * $default-thickness;
    }

    &__icon {
      height: 18px;
      width: 16px;
    }
  }

  .no-requisitions {
    padding: $default-thickness;
  }

  .requisitions-loading-spinner {
    padding-bottom: $default-thickness;
  }
}
