@mixin purchase-requisition-error-list($theme) {
  $layout: map-get($theme, layout);

  $light-blue: mat-color($layout, 50);
  $primary-red-color: mat-color($mp-state, red);
  $bg-color: #fffbfb;
  $odd-bg-color: #fff7f7;
  $border-color: #ffe5e5;

  .mpdm-purchase-requisition-error-list {
    border-top: 1px solid $border-color;

    li {
      color: $primary-red-color;
      padding: 0.5 * $default-thickness 2 * $default-thickness;
      background-color: $bg-color;
      border-bottom: 1px solid $border-color;

      &:nth-child(odd) {
        background-color: $odd-bg-color;
      }

      &:last-child {
        border-bottom: 0;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        padding: 0;
      }
    }
  }
}

:host {
  display: block;

  ul {
    list-style: none;
    overflow-y: auto;
    max-height: 130px;
    padding: 0;
    margin: 0;
  }
}
