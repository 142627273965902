@mixin mp-nav-profile($theme) {
  $foreground: map-get($theme, foreground);
  $accent: map-get($theme, accent);

  $user-foreground: mat-color($accent, 500);
  $company-foreground: mat-color($foreground, text);

  .mp-nav-profile {
    display: flex;
    flex-direction: column;
    height: 160px;
    padding-left: 72px;
    padding-top: 32px;

    .profile-user {
      margin-top: $default-thickness;
      color: $user-foreground;
      @include mat-typography-level-to-styles($mp-typography, subheading-2);
    }

    .profile-company {
      color: $company-foreground;
      @include mat-typography-level-to-styles($mp-typography, button);
    }

    &--small {
      height: 50px;
      padding: $default-thickness 0 0;
      align-items: center;
    }
  }
}
