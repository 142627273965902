@use 'sass:list';

@use 'colors/utilities' as color-utils;

@import 'colors';

@mixin _tab-label-focus-color($tab-focus-color) {
  .mat-tab-label,
  .mat-tab-link {
    &.cdk-keyboard-focused,
    &.cdk-program-focused {
      background-color: color-utils.add-opacity-to-color-variable($tab-focus-color, 0.3);
    }
  }
}

@mixin tabs-overrides {
  .mat-tab-group,
  .mat-tab-nav-bar {
    $theme-colors: (
      primary: (
        $color-primary-100,
        $color-primary-contrast-500,
      ),
      accent: (
        $color-accent-100,
        $color-accent-contrast-500,
      ),
      warn: (
        $color-warn-100,
        $color-warn-contrast-500,
      ),
    );

    @each $name, $colors in $theme-colors {
      $focus-color: list.nth($colors, 1);
      $background-color: list.nth($colors, 2);

      &.mat-#{$name} {
        @include _tab-label-focus-color($focus-color);
      }

      &.mat-background-#{$name} {
        @include _tab-label-focus-color($focus-color);

        > .mat-tab-header .mat-tab-label,
        > .mat-tab-link-container .mat-tab-link {
          &.mat-tab-disabled {
            color: $background-color;
          }
        }
      }
    }
  }
}
