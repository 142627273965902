@import 'colors';

$font-mono: 'Courier New', Courier, monospace;

@mixin cart-positions-table-theme($theme) {
  .mpms-cart-positions-table {
    --table-first-cell-left-padding: #{$table-cell-padding};

    mat-paginator {
      margin-right: $default-thickness * 1.5;
      color: $color-gray-600;
    }

    mpdm-article-item-unit {
      text-align: center;
    }

    .cart-positions-table__loading-indicator {
      padding: $default-thickness 0;
    }

    .cart-positions-table__row {
      height: 90px;
    }

    .cart-positions-table__header {
      border-top: 1px $table-border-color solid;
    }

    .mat-column-articleImage {
      flex: 0 0 90px;
      height: 100%;
    }

    .mat-column-articleImage:not(.mat-header-cell) {
      background-color: white;
      border-right: 1px $table-border-color solid;

      .mpcm-article-image {
        margin-right: 0;

        --image-placeholder-background-color: transparent;
      }
    }

    .mat-column-articleName {
      mpdm-article-item-details {
        width: 100%;
      }
    }

    .mat-column-articleQuantity {
      flex: 0 0 15%;
      min-width: 130px;
    }

    .mat-column-articleUnit {
      flex: 0 0 15%;
      text-align: center;
    }

    .mat-column-articlePrice {
      flex: 0 0 20%;
    }

    .mat-column-articleRemove {
      flex: 0 0 72px;
      color: $color-gray-600;
      padding: 0;
    }

    .placeholder-text {
      font-size: 13px;
      color: gray;
    }
  }
}
