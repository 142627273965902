@mixin highlight-animation($color-highlight, $time: 2s) {
  @keyframes highlight {
    0% {
      background-color: inherit;
    }
    50% {
      background-color: $color-highlight;
    }
    100% {
      background-color: inherit;
    }
  }

  animation: highlight $time linear;
}
