@mixin hitlist-article($theme) {
  $color-gray: #757575;
  $color-light-gray: #c4c4c4;
  $border-color: #e4e4e4;

  .mpms-hitlist-article {
    color: $color-gray;

    .hitlist-article {
      border-top: solid;
      border-width: 1px 0;
      border-color: $border-color;
      padding: 0.5 * $default-thickness 0;

      &__article {
        padding-left: $default-thickness;
      }

      &__rankinfo {
        &-reqcount {
          &-label {
            color: $color-light-gray;
          }
        }

        &-unit {
          color: $color-light-gray;

          &-value {
            color: $color-gray;
            font-weight: bold;
          }
        }
      }
    }
  }
}

:host {
  .hitlist-article {
    display: flex;

    &__rankinfo {
      flex: 1;

      &-reqcount {
        display: flex;
        flex-direction: column;

        &-value {
          font-size: 24px;
        }
      }
    }

    &__article {
      flex: 7;
      margin-bottom: 0;
    }
  }
}
