@mixin mp-overview($theme) {
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $layout: map-get($theme, layout);

  $title-bar-background: mat-color($background, dialog);
  $card-active-background: mat-color($primary, 50);
  $gray: #757575;

  $layout-color: mat-color($layout, 500);

  .mp-overview {
    display: flex;
    min-height: $full;
    max-height: $full;

    .list-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .title-bar {
        display: flex;
        align-items: center;
        height: $default-toolbar-height;
        padding-left: $default-thickness * 2;
        background: $title-bar-background;
        @include mat-typography-level-to-styles($mp-typography, title);

        .title {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 20px;
          color: $layout-color;
          line-height: 24px;

          &__count {
            margin-left: 0.5 * $default-thickness;
            font-size: 16px;
            color: $gray;
            line-height: 14px;
          }
        }
      }

      .search-bar {
        padding: $default-thickness * 0.5 $default-thickness * 2 0;

        mat-form-field {
          width: 100%;
        }
      }

      .list-context {
        padding: $default-thickness $default-thickness 0;
        border-bottom: 1px solid $color-gray-100;

        &:empty {
          display: none;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: auto;
        flex: 1;

        .cdk-virtual-scroll-viewport {
          flex: 1;

          .cdk-virtual-scroll-content-wrapper {
            display: flex;
            flex-direction: column;
          }
        }

        .mat-card {
          margin: $default-thickness;
          transition: $default-transition;

          &:hover,
          &.active {
            background: $card-active-background;
            transition: $default-transition;
          }
        }
      }
    }

    .detail-view-container {
      display: flex;
      flex: 2;
      box-shadow: $default-box-shadow;

      > :not(router-outlet) {
        width: 100%;
      }
    }

    .layout-full {
      flex: 1 0 $full;
      transform: translateX(0);
      transition: $container-transition;
      min-width: 0;
      min-height: 0;
    }

    .navigated {
      transform: translateX(-100%);
      transition: $container-transition;
    }

    &--custom {
      .detail-view-container {
        overflow-y: auto;
      }
    }
  }
}
