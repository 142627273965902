@import 'variables';

@mixin category-tiles($theme) {
  $color-gray: mat-color($mp-gray, 300);

  .mpms-category-tiles {
    .widget-title {
      color: $color-gray;
      font-weight: bold;
    }
  }
}

:host {
  display: block;

  .widget-title {
    display: flex;
    align-items: center;
    gap: 0.5 * $default-thickness;
    margin: 0 0 $default-thickness 0;
  }

  .category-tiles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-auto-rows: 1fr;
    gap: $default-thickness;

    &__tile {
      min-height: 170px;
    }
  }
}
