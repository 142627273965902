@import 'colors';

@mixin purchase-requisition-history($theme) {
  $layout: map-get($theme, layout);

  $color-gray: mat-color($mp-gray, 600);

  $primary-black-color: mat-color($mp-gray, 900);
  $secondary-black-color: mat-color($mp-gray, 100);

  $primary-green-color: $color-state-green-500;
  $secondary-green-color: $color-state-green-200;

  $primary-orange-color: $color-gold-450;
  $secondary-orange-color: $color-gold-50;

  $primary-red-color: $color-state-red-500;
  $secondary-red-color: $color-state-red-100;

  $table-row-accent: $color-layout-10;

  .mpdm-purchase-requisition-history {
    background-color: $table-row-accent;

    &--pending {
      --background-image: linear-gradient(
          90deg,
          transparent,
          transparent 50%,
          #{$table-row-accent} 50%,
          #{$table-row-accent} 100%
        ),
        linear-gradient(90deg, #{$secondary-black-color}, #{$color-gray});
      --background-size: 20px 1px, 100% 1px;
      --current-state-primary-color: $primary-orange-color;
      --current-state-background-color: $secondary-orange-color;
    }

    &--approved {
      --background-image: linear-gradient(90deg, #{$secondary-black-color}, #{$primary-green-color});
      --background-size: 100%;
      --current-state-primary-color: #{$primary-green-color};
      --current-state-background-color: #{$secondary-green-color};
    }

    &--rejected {
      --background-image: linear-gradient(90deg, #{$secondary-black-color}, #{$color-gray});
      --background-size: 100%;
      --current-state-primary-color: #{$primary-black-color};
      --current-state-background-color: #{$secondary-black-color};
    }

    &--failed {
      --background-image: linear-gradient(90deg, #{$secondary-black-color}, #{$primary-red-color});
      --background-size: 100%;
      --current-state-primary-color: #{$primary-red-color};
      --current-state-background-color: #{$secondary-red-color};
    }

    .requisition-history {
      padding: 0.5 * $default-thickness 2 * $default-thickness;
      gap: $default-thickness * 1.5;

      &__progress {
        border: none;
        height: 2px;
        background-image: var(--background-image);
        background-size: var(--background-size);
      }

      mpdm-purchase-requisition-current-state {
        .requisition-state__current-state {
          &-icon {
            color: var(--current-state-primary-color);
          }
          &-details {
            &-label {
              color: var(--current-state-primary-color);
              background-color: var(--current-state-background-color);
            }
          }
        }
      }
    }
  }
}

:host {
  .requisition-history {
    display: flex;
    align-items: center;

    &__progress {
      flex: 1;
    }

    &__actions {
      flex: 0 0 40px;
    }
  }
}
