@import 'variables';
@import 'mixins';

@mixin badge-updates($theme) {
  $text-gray: mat-color($mp-gray, 300);
  $color-orange: mat-color($mp-state, orange);
  $shady-gray: #f6f6f6;

  .mp-badge-updates {
    color: $text-gray;

    .badge-header {
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;

      &__count {
        background-color: $shady-gray;
        color: $color-orange;
        font-weight: bold;
      }

      &__icon {
        font-size: 20px;
        line-height: 20px;
        color: $color-orange;
      }
    }

    &.active {
      color: $color-orange;

      .badge-header {
        font-weight: bold;

        &__count {
          background-color: $shady-gray;
        }
      }
    }
  }
}

:host {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 2px;
  margin-left: $default-thickness;

  .badge-header {
    display: inline-block;
    vertical-align: middle;

    &__count {
      border-radius: 10px;
      padding: 2px 6px;
      margin-right: 2px;
    }

    &__icon {
      height: 20px;
      width: 20px;
      vertical-align: middle;
    }

    &__label {
      display: none;

      @include breakpoint(xl) {
        display: inline;
      }
    }
  }
}
