@use 'sass:meta';

@mixin file-upload-label($theme) {
  $color-error-foreground: mat-color($mp-state, red);
  $color-success-foreground: mat-color($mp-state, green);

  .mpcm-file-upload-label {
    &--success {
      --current-state-primary-color: #{$color-success-foreground};
    }

    &--error {
      --current-state-primary-color: #{$color-error-foreground};
    }

    .dropzone-label {
      &__text {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: var(--current-state-primary-color);

        &--bold {
          font-weight: bold;
          margin-bottom: 2px;
        }
      }

      mat-icon.dropzone-label__icon {
        width: 40px;
        height: 40px;
        min-width: 40px;
        font-size: 40px;
        margin-right: 12px;
        color: var(--current-state-primary-color);
      }
    }

    .mat-stroked-button.upload-button {
      background: white;
      border-color: white;
      box-shadow: 0px 1px 2px 0px #00000015;
      margin: 8px 0;
    }
  }
}
:host {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .dropzone-label {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
