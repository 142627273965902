@mixin search-view-action-buttons-global {
  .mpcm-search-view-action-buttons {
    &__menu {
      .mat-menu-content:not(:empty) {
        padding-block: 0;
      }
    }

    &__search-view-name-input {
      min-width: 280px;
    }
  }
}
