@import 'variables';
@import 'colors';

@mixin requisitions-period-selector($theme) {
  $accent: map-get($theme, accent);
  $color-accent: mat-color($accent, 500);

  $color-gray: mat-color($mp-gray, 600);

  $color-gold: $color-gold-50;

  .mpdm-requisitions-period-selector {
    .mat-button-toggle-group {
      border: none;

      mat-button-toggle.mat-button-toggle {
        border: none;
        font-size: 14px;
        font-weight: bold;
        color: $color-gray;

        &-checked {
          background-color: white;
          color: $color-accent;
        }

        &:hover {
          background-color: $color-gold;

          .mat-button-toggle-focus-overlay {
            opacity: 0;
          }
        }
      }
    }
  }
}

:host {
  display: block;

  .requisition-statistics {
    display: flex;
    flex-wrap: wrap;
    gap: $default-thickness;

    &__approved,
    &__rejected,
    &__total {
      flex: 1;
      text-align: center;
    }
  }
}
