@import 'colors';
@import 'mixins';
@import 'variables';

@mixin mp-filter-item-selector {
  $text-gray: #757575;
  $text-gray-dark: #454545;

  .mp-filter-item-selector {
    display: flex;
    flex-direction: column;

    height: 100%;

    .search-field {
      flex: 0;
      margin-bottom: 0.5 * $default-thickness;
    }

    .status-bar {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0.5 * $default-thickness;

      .option-counter-col {
        flex: 1;
        display: flex;
        align-items: center;

        font-size: smaller;
        opacity: 0.5;

        mat-checkbox {
          margin-bottom: 2px;
          margin-right: 0.5 * $default-thickness;
        }
      }

      .toggle-show-all-button-col {
        flex: 0 0 40px;
      }
    }

    .item-list {
      flex: 1 0;
      max-height: 300px;
      transition: max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1);
      overflow-y: auto;

      .mp-filter-item {
        position: relative;
        display: block;
        overflow: visible;

        background: #f6f6f6;
        margin-bottom: 2px;
        padding: 0;
        color: $text-gray;
        border-radius: $default-border-radius;
        font-weight: bold;

        mat-icon svg path {
          fill: $text-gray;
        }

        .mat-radio-label,
        .mat-checkbox-layout {
          padding: $default-thickness * 0.5 $default-thickness;
          box-sizing: border-box;
        }

        &:hover {
          color: $text-gray-dark;
          background-color: $color-gold-50;

          mat-icon svg path {
            fill: $text-gray-dark;
          }
        }

        &.mat-checkbox-checked > .mat-checkbox-layout {
          color: $text-gray-dark;
        }
        &.mat-checkbox-checked > .mat-checkbox-layout mat-icon svg path {
          fill: $text-gray-dark;
        }

        .filter-item-label {
          width: 100%;
          line-height: 24px;
          font-size: 13px;
          white-space: nowrap;
          flex: 0 1 auto;
          min-width: 0;
          color: inherit;
        }

        .filter-item-icon {
          margin: 0 0.5 * $default-thickness 0 0;
          line-height: 0;

          mat-icon {
            @include icon-size(20px);

            &[data-mat-icon-name='transport_clinic'] svg rect {
              fill: $text-gray !important;
            }
          }
        }

        .mat-checkbox-inner-container {
          margin-left: 0;
          margin-right: 0;
        }

        .mat-radio-label-content,
        .mat-checkbox-label {
          flex: 1;
          display: flex;
          min-width: 0;
          line-height: 1.5 * $default-thickness;
          align-items: center;
          padding-left: 10px;
        }

        .mat-checkbox-layout {
          width: 100%;
          display: flex;
        }

        .filter-item-stats {
          flex: 0 0 auto;
          font-size: 12px;
          font-weight: 600;
          color: #989898;
        }
      }
    }

    .show-all-button {
      width: 100%;
    }

    .filter-item-more {
      color: $text-gray;
      cursor: pointer;
      display: block;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0.5 * $default-thickness 0 0 12px;

      &:hover {
        color: $text-gray-dark;
      }
    }

    .no-items-message {
      color: $color-gray-300;
      font-size: 13px;
      font-weight: bold;

      text-align: center;
      margin-bottom: 0;
    }
  }
}
