@import 'variables';

@mixin article-item-main-price-theme($theme) {
  $color-light-gray: mat-color($mp-gray, 300);
  $color-gray-dark: #333;

  .mpdm-article-item-main-price {
    .article-item-main-price {
      font-weight: normal;
      color: $color-light-gray;
      word-break: break-word;

      &__price {
        color: $color-gray-dark;
        font-weight: bold;
      }

      &__price-label {
        white-space: nowrap;
      }
    }
  }
}

:host {
  display: inline-block;

  &.mpdm-article-item-main-price--vertical {
    display: block;

    .article-item-main-price {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &.mpdm-article-item-main-price--big-price {
    .article-item-main-price__price {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .article-item-main-price {
    &__price {
      font-size: 16px;
      line-height: 18px;
      padding-right: 0.25 * $default-thickness;
    }

    &__price-label {
      font-size: 13px;
      line-height: 18px;
    }
  }
}
