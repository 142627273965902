@import 'variables';

@mixin match-proposal-actions-global {
  .mpcm-match-proposal-actions {
    .match-proposal-actions {
      &__button {
        padding: 0;
        min-width: 36px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
