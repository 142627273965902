@mixin contact-form($theme) {
  $accent: map-get($theme, accent);

  $color-accent: mat-color($accent, 500);

  .mpe-contact-form {
    .mailto-link {
      text-decoration: none;
      font-weight: bold;
      color: $color-accent;

      &:hover {
        .mailto-link__text {
          text-decoration: underline;
        }
      }
    }
  }
}

:host {
  display: inline-block;

  .mailto-link {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
