@import 'colors';

@mixin sortable-katalog($theme) {
  $item-border-radius: 4px;

  $color-green-light: #e4efe2;
  $color-green: mat-color($mp-state, green);
  $color-yellow: mat-color($mp-state, yellow);
  $color-orange: mat-color($mp-state, orange);
  $color-red: mat-color($mp-state, red);

  $font-label: 700 #{16px}/#{48px} Roboto;
  $font-meta: 300 #{14px}/#{48px} Roboto;

  $text-gray-light: #767676;
  $text-gray-dark: rgb(36, 36, 36);
  $text-gray-disabled: lighten($text-gray-light, 10%);

  .mp-sortable-catalog {
    &.is--focused {
      .mp-entity-item-slots {
        .entity-item {
          box-shadow: inset 0px 0px 1px 1px $color-green;
          background-color: $color-green-light;

          .entity-item__slot-actions {
            background-color: $color-green-light;
          }
        }
      }
    }

    .mp-entity-item-slots {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently */

      &.item--removed {
        opacity: 0.5;

        .mat-icon {
          color: $text-gray-disabled;
        }

        .truncate,
        mp-catalog-covin-badge span {
          color: $text-gray-disabled;
          text-decoration: line-through;
        }
      }

      .entity-item {
        &__slot-content {
          display: flex;
          flex: 1 1 auto;
          align-items: baseline;

          min-width: 0;
          padding: 0 0 0 $default-thickness * 1.5;
          margin: 0;

          font: $font-label;
          color: $text-gray-light;

          mp-catalog-type-icon {
            mat-icon {
              vertical-align: middle;
              font-size: 20px;
              margin: 0 8px 0 0;
            }
          }

          .name {
            transition: flex 350ms cubic-bezier(0.4, 0, 0.2, 1);
            transform: translate3d(0, 0, 0);

            &:hover {
              transform: translate3d(0, 0, 0);
              flex: 0 0 auto;
            }
          }

          .meta {
            min-width: 40px;
            transition: flex 350ms cubic-bezier(0.4, 0, 0.2, 1);

            &.meta--date {
              flex: 1 0 auto;
              text-align: right;
              margin: 0 $default-thickness 0 0;
              transform: translate3d(0, 0, 0);

              &:hover {
                transform: translate3d(0, 0, 0);
                flex: 1 0 auto;
                color: $text-gray-dark;
              }
            }

            &:hover {
              flex: 0 0 auto;
              color: $text-gray-dark;
            }
          }

          .mp-catalog-covin-badge {
            display: inline-block;
            margin: 0 $default-thickness * 2 0 0;
            flex: 0 1 auto;
          }
        }
      }

      mp-entity-item-footer {
        display: flex;
        width: 100%;
        margin-top: -4px;

        .entity-up {
          width: 100%;
          padding: 6px $default-thickness 4px;
          display: flex;
          box-sizing: border-box;
          min-width: 0;
          margin: 1px;
          background-color: #fff9f1;
          align-items: center;
          border-radius: 0 0 $item-border-radius $item-border-radius;
        }

        .entity-up__item {
          display: flex;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          padding: 4px 8px;
          margin: 0 $default-thickness 0 0;
          flex: 0 0 auto;
          align-items: center;
          box-sizing: border-box;
          height: 22px;

          .entity-up__count {
            border-radius: 10px;
            display: inline-block;
            padding: 1px 8px 0 8px;
            line-height: 19px;
            margin-right: 6px;
          }

          &--new {
            color: #5da34b;
            .entity-up__count {
              background: rgba(130, 201, 112, 0.25);
            }
          }
          &--mod {
            color: $color-orange;
            .entity-up__count {
              background: rgba(232, 125, 0, 0.25);
            }
          }
          &--del {
            color: $color-red;
            .entity-up__count {
              background: rgba(201, 112, 112, 0.25);
            }
          }
          &--covin {
            mp-catalog-covin-badge {
              margin: 0;
              .val {
                color: $text-gray-light;
              }
            }
          }
        }

        .entity-up__spacer {
          flex: 1 1 auto;
        }

        .entity-up__action {
          flex: 0 0 auto;
          z-index: 12;
          line-height: 0;

          button {
            line-height: 26px;
            box-sizing: content-box;
            min-width: 0;
            color: $color-orange;

            &:not(.mat-button-disabled) {
              border-color: currentColor;
            }

            mat-icon {
              display: inline-block;
              font-size: 20px;
              line-height: 22px;
            }
          }
        }
      }
    }

    &:hover {
      .entity-item {
        transition: flex 350ms cubic-bezier(0.4, 0, 0.2, 1);
        box-shadow: inset 0px 0px 1px 1px #ffb300;
        background-color: #fffbf3;

        &__slot-content {
          color: $text-gray-dark;
        }
        &__slot-actions {
          background-color: #fffbf3;
        }
      }
    }
  }
}
