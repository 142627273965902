@import 'variables';

@mixin shop-favorites-menu($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  $accent-row: #f7fafa;

  $color-accent: mat-color($accent, 500);

  $color-primary: mat-color($primary, 500);

  $color-gray: mat-color($mp-gray, 600);
  $color-gray-light: mat-color($mp-gray, 300);

  .mpms-favorites-menu {
    .favorites__btn {
      color: $color-accent;
      &--no-favorites {
        color: $color-gray-light;

        &:hover,
        &[aria-expanded='true'] {
          color: $color-accent;
        }
      }
    }

    &__lists-menu {
      &.mat-menu-panel {
        min-width: 280px;
        max-width: none;

        .mat-menu-content:not(:empty) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }

      .lists-menu {
        &__list-row {
          flex-grow: 2;

          &.active {
            font-weight: 700;
            color: $color-primary;
          }

          &:nth-child(even) {
            background-color: $accent-row;
          }
        }

        &__no-lists-view {
          display: block;
          padding: $default-thickness;
          text-align: center;
          color: $color-gray;
        }

        &__action-divider {
          border-top-color: $color-accent;

          &--highlited {
            border-top-color: $color-primary;
          }
        }
      }
    }
  }
}

:host {
  display: inline-block;
}
