@import 'variables';
@import 'colors';

@mixin export-item-messages($theme) {
  $primary-orange-color: $color-gold-450;
  $secondary-orange-color: $color-gold-50;
  $border-orange-color: $color-gold-250;

  $primary-red-color: $color-state-red-500;
  $secondary-red-color: $color-state-red-100;
  $border-red-color: $color-state-red-300;

  .mpcm-export-item-messages {
    cdk-virtual-scroll-viewport {
      height: 100%;
      position: relative;

      .cdk-virtual-scroll-content-wrapper {
        width: 100%;
      }
    }

    .messages-list__item {
      font-size: 13px;

      &:not(:last-child) {
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      &--error {
        color: $primary-red-color;
        background-color: $secondary-red-color;
        border-color: $border-red-color;
      }

      &--warning {
        color: $primary-orange-color;
        background-color: $secondary-orange-color;
        border-color: $border-orange-color;
      }
    }
  }
}

:host {
  .messages-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &__item {
      padding: 4px 1.5 * $default-thickness;
      height: 30px;
      box-sizing: border-box;
    }
  }
}
