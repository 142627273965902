@mixin expandable-panel-global {
  .mp-expandable-panel {
    mat-expansion-panel {
      &.panel {
        .mat-content {
          overflow: visible;

          &.mat-content-hide-toggle {
            margin-right: 0;
          }
        }
      }
    }

    &.mp-expandable-panel--no-padding {
      mat-expansion-panel {
        &.panel {
          .mat-expansion-panel-body {
            padding: 0;
          }

          .panel__header {
            padding: 0;
          }
        }
      }
    }

    &.mp-expandable-panel--stacked {
      mat-expansion-panel {
        &.panel {
          border-radius: 0;
        }
      }

      .panel-card {
        border-radius: 0;
      }
    }
  }
}
